<template>
  <Teleport to='body'>
    <div class='core-modal__container'>
      <div class='core-modal__content'>
        <div class="core-modal__header">
          <h5 class="modal-title text-bold">
            {{ selectedResponse.isRead ? 'Причина отклонения' : 'Опишите причину отклонения' }}
          </h5>
          <button type="button" class="btn-close chat-load-files__close" @click='onClose'>
          </button>
        </div>
        <core-form v-if='!selectedResponse.isRead' :url='computedUrl' :initialValidation='declineSchema'
          :onSuccess="onClose" v-slot="{ errors, isLoading }" :auth='true'>
          <div class="input-block mb-3">
            <core-textarea-input required showLabel label='Причина отказа' :disabled="isLoading" :data="data"
              name='reason' :rows="5" :showError="true" :errors="errors" />
          </div>
          <core-submit-button className='btn btn-danger core-button' type="submit" text='Отклонить'
            :isLoading='isLoading' />
        </core-form>
        <div v-else class='decline-response__description'>
          <p>{{ selectedResponse.description }}</p>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue';
import { declineSchema } from './decline-response.options.ts'
const props = defineProps({
  url: String,
  selectedResponse: Object,
  onClose: Function
})

const computedUrl = computed(() => {
  return `${props.url}/decline?response_id=${props.selectedResponse.responseId}`
})

const data = ref({
  reason: ''
})

</script>
<template>
  <forming-request-modal v-if='createFormingRequestModal.isShow' :name='createFormingRequestModal.name'
    :onClose='setFormingRequestModal' :mode='FormingRequestMode.FIX_PRICE_MODE' :requestId='id' :url='fpRequestsUrl' />
  <resource-fixed-release-card v-if='resourceCardState.isShow && user.isResourseManagerRole' :id='resourceCardState.id'
    :onClose='showResourceCardState' :name='resourceCardState.name'></resource-fixed-release-card>
  <user-contacts-modal v-if='selectedContact' :selectedContact :onClose='onCloseContactsModal' />
  <div class="offcanvas offcanvas-end request-card-info-container show" tabindex="-1" id="request-card-info"
    aria-labelledby="request-card-infoLabel">
    <modal-header :title='data.title' :onClose='() => onClose(false)' />

    <div class="offcanvas-body">
      <div class="card-body">
        <ul class="nav nav-tabs nav-tabs-bottom nav-justified core-canvas__tabs">
          <li class="nav-item">
            <a class="nav-link active" href="#bottom-justified-tab1" data-bs-toggle="tab">Информация</a>
          </li>
          <li class="nav-item">
            <a :class='user.isResourseManagerRole && "d-none disabled"' class="nav-link" href="#bottom-justified-tab2"
              data-bs-toggle="tab">
              Отклики
              <span class="badge rounded-pill bg-primary"
                v-if='resourceTabRef && resourceTabRef.meta && resourceTabRef.meta.metaData && resourceTabRef.meta.metaData.new_responses_count'>
                {{ resourceTabRef.meta.metaData.new_responses_count }}
              </span>
            </a>
          </li>
        </ul>

        <div class="tab-content">
          <div class="tab-pane show active" id="bottom-justified-tab1">
            <fix-price-info-tab :data='data' />
          </div>

          <div class="tab-pane show" id="bottom-justified-tab2">
            <resources-tab v-if='!user.isResourseManagerRole' :url='fpResponsesUrl' :mode='ContractModelEnum.FIX_PRICE'
              :headers='user.isITManagerRole ? itManagerHeaders : purchasingManagerHeaders' ref='resourceTabRef' />
          </div>
        </div>
        <div
          v-if='user.isITManagerRole && (!data.status || !getStatusPermission(data.status, [StatusesEnums.ARCHIVED, StatusesEnums.SUSPENDED]))'
          class="buttons_container">
          <core-submit-button type='button' :onClick='createRequest' className="btn btn-primary submit-btn"
            text='Сформировать Заявку' :isLoading='isLoading' />
          <core-submit-button className="btn btn-primary submit-btn" text='Приостановить' :isLoading='isLoading'
            :onClick='suspendRequest' />
        </div>
        <div v-if='user.isResourseManagerRole' class="buttons_container">
          <button class="btn btn-primary submit-btn" type='button' @click='showResourceCardState(true)'>
            Оставить отклик
          </button>
          <button class='btn btn-primary submit-btn' type='button'
            @click='openContactsModal(data.id, EntitiesEnum.REQUEST, ContractModelEnum.FIX_PRICE, data.user.telegram)'>
            Связаться
          </button>
        </div>
      </div>
    </div>
    <!-- end offcanvas-body-->
  </div>
  <div class="offcanvas-backdrop fade show" @click='onClose(false)'></div>
</template>
<script setup>
import { useUserStore } from '@/stores/user.ts';
import request from '@/shared/core-api/index.ts';
import { ref, onMounted } from 'vue';
import { StatusesEnums, getStatusPermission } from '@/pages/requests/index.ts';
import { useResourceCard } from '@/features/requests/index.ts';
import { useToast } from 'vue-toast-notification';
import {
  fpRequestsUrl, purchasingManagerHeaders, itManagerHeaders
} from './fix-price-request-card-info.options.ts';
import { FormingRequestMode, useFormingModalRequest } from '@/entities/forming-request-modal/index.ts';
import { ContractModelEnum } from "@/shared/enums/index.ts";
import { useContactsModal } from '@/features/get-user-contacts/index.ts'
import { EntitiesEnum } from '@/shared/enums/index.ts';
const { createFormingRequestModal, setFormingRequestModal } = useFormingModalRequest();
const { selectedContact, openContactsModal, onCloseContactsModal } = useContactsModal()
const props = defineProps({
  id: Number | String,
  onClose: null,
});

const fpResponsesUrl = `${fpRequestsUrl}/${props.id}/responses`;
const resourceTabRef = ref();

const { resourceCardState, showResourceCardState } = useResourceCard(props.id);
const user = useUserStore();
const data = ref({});
const isLoading = ref(false);
const $toast = useToast();
onMounted(() => {
  getRequestData(props.id)
});

const suspendRequest = async () => {
  try {
    isLoading.value = true;
    await request(`${fpRequestsUrl}/${props.id}/suspend`, {
      method: 'POST',
      auth: true
    })
    isLoading.value = false;
    props.onClose(false);
  } catch (e) {
    isLoading.value = false;
  }
}

const getRequestData = async (id) => {
  try {
    const { data: responseData } = await request(`${fpRequestsUrl}/${id}`, {
      auth: true
    });

    data.value = responseData;
    resourceCardState.value.name = responseData.number;
    createFormingRequestModal.value.name = responseData.number;
  } catch (e) {

  }
}

const createWarning = () => {
  $toast.clear();
  $toast.open({
    type: 'warning',
    message: `
      <div class='error-core-container'>
        <div class='error-core-container__cross'>
          <b-button type="button" class="btn-close text-reset" aria-label="Close"></b-button>
        </div>
        <p class="py-0 my-0 w-100">
          Вы не можете создать заявку.
        </p>
        <p class="py-0 my-0 w-100">У Вас нет согласованных откликов</p>
      </div>
    `,
    duration: 5000,
    position: 'bottom-left'
  });
}

const createRequest = async () => {
  try {
    isLoading.value = true;
    const { data } = await request(`${fpResponsesUrl}/accepted`, {
      auth: true,
    });

    if (data.length === 0) {
      createWarning();
      isLoading.value = false;
      return;
    }
    isLoading.value = false;
    setFormingRequestModal(true);
    return;
  } catch (e) {
    isLoading.value = false;
  }
}

</script>

import request from "@/shared/core-api/index.ts";

export const getChatInfo = async (id) => {
  try {
    const data = await request(`/chat/${id}`, {
      auth: true,
    });

    return data;
  } catch (e) {}
};

export function trancateText(text, count) {
  if (!text) {
    return text;
  }

  if (text.length > count) {
    return `${text.slice(0, count + 1)}...`;
  }

  return text;
}

import * as Yup from "yup";
import { FieldsValidation } from "@/shared/validation/index.ts";

export const schema = Yup.object().shape({
  user: Yup.object().shape({
    name: FieldsValidation.notEmptyString,
    email: FieldsValidation.email,
    phone: FieldsValidation.phoneNumber,
    telegram: FieldsValidation.notEmptyString,
  }),
  company: Yup.object().shape({
    short_name: FieldsValidation.companyName,
    type: FieldsValidation.notEmptyString,
  }),
  files: FieldsValidation.files,
});

<template>
  <success-modal :title='modalTitle'
    :body='`Новый пользователь успешно зарегистрирован. Учетные данные успешно отправлены на почту ${emailRef}`'
    :button='modalButton' />
  <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#success-modal" ref='achorRef'></a>
  <div class="content container-fluid">
    <breadcrumb-header />
    <admin-users-filters :filters='filters' :resetFilters='resetFilters' />
    <core-table :headers='headers' url='/admin/users' ref='tableRef' :initialPage='initialPage'
      :initialData='initialFiltersState' :isSaveFilters='true'>
      <template v-slot:placeholder>
        <div class='showentries mb-3 ants-style-4'>
          <div>
            <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified">
              <li class="nav-item">
                <a class="nav-link h-100 rounded-tabs" href="#solid-rounded-justified-tab1" data-bs-toggle="tab"
                  :class='(!filters.blocked || filters.blocked === 0) && "active"'
                  @click="() => setBlockedMode(0)">Активные</a>
              </li>
              <li class="nav-item">
                <a class="nav-link h-100 rounded-tabs" href="#solid-rounded-justified-tab2" data-bs-toggle="tab"
                  :class='filters.blocked === 1 && "active"' @click="() => setBlockedMode(1)">Заблокированные</a>
              </li>
              <li class="nav-item">
                <a class="nav-link h-100 rounded-tabs" @click='() => showAddUserModal(true)'
                  style="min-width:max-content">+ Добавить
                  пользователя</a>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template #item-name="{ name, image_path }">
        <div class='d-flex align-items-center'>
          <div class="table-avatar table-avatar">
            <span class="user-img me-3"><img class='header-avatar' :src="image_path || photoPlaceholder"
                alt="User Image" /></span>
          </div>
          {{ name }}
        </div>
      </template>
      <template #item-company_short_name='{ company }'>
        <div>
          {{ company && company.short_name }}
        </div>
      </template>
      <template #item-created_at='{ created_at }'>
        <div>
          {{ fromTimestampToISOString(created_at && created_at * 1000) }}
        </div>
      </template>
      <template #item-role="{ role }">
        <div>
          <div class='badge bg-inverse-info'>{{ role }}</div>
        </div>
      </template>
      <template #item-actions="{ id, blocked }">
        <div class="text-end">
          <div class="dropdown dropdown-action">
            <a href="javascript:;" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
              aria-expanded="false"><i class="material-icons">more_vert</i></a>
            <div class="dropdown-menu dropdown-menu-right">
              <a v-if='(!filters.blocked || filters.blocked === 0)' class="dropdown-item" @click='setId(id, blocked)'
                href="javascript:;">
                <span class='action-icon-container'><i class="fa-solid fa-pencil"></i></span>
                Редактировать
              </a>
              <a v-if='filters.blocked === 1' class="dropdown-item" @click='setId(id, blocked)' href="javascript:;">
                <span class='action-icon-container'>
                  <i class="fa fa-external-link"></i>
                </span>
                Открыть
              </a>
              <a class="dropdown-item" @click='blockUser(id, blocked)'>
                <span class='action-icon-container'>
                  <i class="fa m-r-5" :class='blocked ? "fa-unlock" : "fa-lock"'></i>
                </span>
                {{ blocked ? 'Разблокировать' : 'Заблокировать' }}</a>
            </div>
          </div>
        </div>
      </template>
    </core-table>
  </div>
  <div v-if='userId.id'>
    <edit-employer :id='userId.id' :onClose='setId' :blocked='userId.blocked'></edit-employer>
  </div>
  <div v-if='addUserModal.isShow'>
    <add-employer :onClose='showAddUserModal' :onModalSuccess='onModalSuccess'></add-employer>
  </div>
</template>
<script setup>
import { fromTimestampToISOString } from '@/shared/utils/date.ts'
import { reactive, ref } from 'vue';
import request from '@/shared/core-api/index.ts';
import photoPlaceholder from '@/assets/img/profiles/user-avatar.png';
import { useAdminUsersFilters } from '@/widgets/filters/index.ts';
import { headers } from './admin-users.options.ts';
const tableRef = ref();
const achorRef = ref();
const emailRef = ref('')

const modalTitle = 'Отличная работа!';
const modalButton = 'Продолжить';

const updateServerOptions = (data, resetPage = false) => {
  tableRef.value.updateServerOptions(data, resetPage)
}

const {
  setBlockedMode,
  filters,
  initialFiltersState,
  initialPage,
  resetFilters
} = useAdminUsersFilters(updateServerOptions);

const userId = reactive({
  id: null
})

const addUserModal = reactive({
  isShow: false
})

const setId = (id, isBlocked) => {
  userId.id = id;
  userId.blocked = !!isBlocked

  if (!id) {
    tableRef.value.updateServerOptions()
  }
}

const showAddUserModal = (isShow) => {
  addUserModal.isShow = isShow;
  if (!isShow) {
    tableRef.value.updateServerOptions()
  }
}

const blockUser = async (id, blocked) => {
  const url = `/admin/users/${id}/${blocked ? 'unblock' : 'block'}`
  await request(url, {
    method: "POST",
    auth: true,
    headers: {
      'Accept': 'application/json',
    }
  })
  tableRef.value.loadFromServer()
}

const onModalSuccess = (email) => {
  emailRef.value = email;
  achorRef.value.click();
  showAddUserModal(null);
}
</script>

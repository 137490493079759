<template>
  <file-view-modal v-if='selectedFile' :file='selectedFile' :onClose='onLocalFileClose' />
  <user-contacts-modal v-if='selectedContact' :selectedContact :onClose='onCloseContactsModal' />
  <decline-response v-if='selectedDeclineResponse.isShow' :selectedResponse='selectedDeclineResponse'
    :onClose='closeDeclineModal' :url='url' />
  <core-table :url='url' :headers='headers' ref='tableRef' :minHeight='0'>
    <template #item-status="{ status, reason }">
      <div v-if='!isRefused(status)' :class='getStatusClass(status)' class='p-2 text-center'>
        {{ status }}
      </div>
      <Tooltip v-else :title="trancateText(reason, 120)" placement="leftBottom">
        <div :class='[getStatusClass(status), "cursor-pointer"]' @click='onStatusClick(reason)' class='p-2 text-center'>
          {{ status }}
        </div>
      </Tooltip>
    </template>
    <template #item-pay_rate="{ pay_rate }">
      {{ pay_rate }} руб
    </template>
    <template #item-cost="{ cost }">
      {{ cost }} руб
    </template>
    <template #item-created_at='{ created_at }'>
      <div>
        {{ fromTimestampToISOString(created_at && created_at * 1000) }}
      </div>
    </template>
    <template #item-file="{ files }">
      <div v-if="files && files[0]" @click='openFile(files[0])'
        class='w-100 d-flex justify-content-center cursor-pointer'>
        <file-icon className='row-file-icon' :fileName='files[0].name' customExt='download' />
      </div>
    </template>
    <template #item-actions="{ id, status, ...data }">
      <div class="buttons_vertical_container p-2">
        <core-submit-button spinnerOptions='text-light spinner-border-sm' className='width-25 btn btn-success btn-sm'
          text='Принять' :disabled='!getStatusPermission(status, [StatusesEnums.NEW, StatusesEnums.CONSIDIRATION])'
          :isLoading='isLoading' @click='changeStatus(id, StatusesEnums.AGREED)' />
        <core-submit-button spinnerOptions='text-light spinner-border-sm' :isLoading='isLoading'
          className='btn btn-primary btn-sm width-25' text='Связаться'
          @click='openContactsModalLocal({ id, ...data })' />
        <core-submit-button spinnerOptions='text-light spinner-border-sm'
          :disabled='!getStatusPermission(status, [StatusesEnums.NEW, StatusesEnums.CONSIDIRATION])'
          :isLoading='isLoading' className='btn btn-danger btn-sm width-25' text='Отклонить'
          @click='openDeclineModal({ responseId: id })' />
      </div>
    </template>
  </core-table>
</template>
<script setup>
import { ref } from 'vue';
import { getStatusClass, isRefused, StatusesEnums, getStatusPermission } from '@/pages/requests/index.ts';
import { fromTimestampToISOString } from '@/shared/utils/date.ts';
import { useFileModal } from '@/widgets/file-view-modal/index.ts';
import { ContractModelEnum } from "@/shared/enums/index.ts";
import request from '@/shared/core-api/index.ts';
import { useContactsModal } from '@/features/get-user-contacts/index.ts'
import { Tooltip } from "ant-design-vue";
import { useDeclineModal } from "@/features/requests/index.ts"
import { trancateText } from '@/shared/utils/index.ts';
import { EntitiesEnum } from '@/shared/enums/index.ts';
const props = defineProps({
  url: String,
  headers: Array,
  mode: String
})

const { selectedFile, openFile, closeFile } = useFileModal();
const { selectedContact, openContactsModal, onCloseContactsModal } = useContactsModal()
const tableRef = ref();
const isLoading = ref(false);

const updateTable = () => {
  tableRef.value.updateServerOptions()
}

const { selectedDeclineResponse, openDeclineModal, closeDeclineModal } = useDeclineModal(updateTable)

const onLocalFileClose = () => {
  closeFile();
  updateTable();
}

const changeStatus = async (response_id) => {
  try {

    isLoading.value = true;
    const url = `${props.url}/accept?response_id=${response_id}`

    await request(url, {
      method: 'POST',
      auth: true,
    })
    isLoading.value = false;
    updateTable()
  } catch (e) {
    isLoading.value = false;
  }
}

defineExpose({
  meta: tableRef,
})

const onStatusClick = (description) => {
  openDeclineModal({ description, isRead: true })
}

const openContactsModalLocal = ({ company_user_telegram, id }) => {
  openContactsModal(id, EntitiesEnum.RESPONSE, props.mode, company_user_telegram)
}

</script>
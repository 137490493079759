export const TooltipEnum = {
  NAME: "Иванов Иван Иванович",
  EMAIL: "example@yandex.ru",
  PHONE: "+7 (912) 555 22 22",
  COMPANY_FULL_NAME: "Компания 'Larson LLC'",
  COMPANY_SHORT_NAME: "Larson LLC",
  TIN: "1234567890",
  TIN_IP: "123456789012",
  OGRN_IP: "123456789012345",
  OGRN: "1234567890123",
  KPP: "123456789",
  OKPO: "12345678",
  OKPO_IP: "1234567890",
  OKVED: "12.34.56",
  INDEX: "123456",
  COUNTRY: "Россия",
  CITY: "г. Москва",
  STREET: "Ильфата Закирова",
  HOUSE: "25д",
  BUILDING: "6",
  APARTMENT: "оф. 50",
  BANK_NAME: "Альфа-Банк",
  BIK: "044525225",
  CORRESPONDENT_ACCOUNT: "30101810200000000225",
  CURRENT_ACCOUNT: "40702810900000012345",
  CONTRACT_NUMBER: "XX123456",
  TELEGRAM: "@nickname",
};

import { defineStore } from "pinia";

export const useChatNotification = defineStore("chatNotification", {
  persist: false,
  state: () => {
    return {
      chat_unread_count: 0,
    };
  },
  actions: {
    setCounter(counter) {
      this.chat_unread_count = counter || 0;
    },
  },
});

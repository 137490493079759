import { useUserStore } from "@/stores/user.ts";
import { useChatHistory } from "@/entities/chat/index.ts";
import { deleteCookie } from "@/shared/utils/cookie.ts";

export function resetAccountData() {
  const user = useUserStore();
  const chatHistory = useChatHistory();

  deleteCookie("token");
  user.$reset();
  chatHistory.$reset();
}

<template>
  <div class="tab-pane" :id="id">
    <div>
      <div class="row">
        <div class="col-sm-6">
          <div class="input-block mb-3">
            <label class="col-form-label">Роль <span class="text-danger">*</span></label>
            <core-select-input :fieldsName='fieldsName' label='Роль' name='user.role' :data='data' :showError='true'
              :errors='errors' :options='roleOptions' :disabled='blocked' />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="input-block mb-3">
            <label class="col-form-label">Фамилия Имя Отчеcтво <span class="text-danger">*</span></label>
            <core-text-input name='user.name' :fieldsName='fieldsName' label='Фамилия Имя Отчеcтво' :data='data'
              placeholder='Введите ваше ФИО' :stringOptions="stringOptions" mask="A A A" :disabled='blocked'
              :showError='true' :errors='errors' :tooltip='TooltipEnum.NAME' />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="input-block mb-3">
            <label class="col-form-label">Электронная почта <span class="text-danger">*</span></label>
            <core-email-input name='user.email' :fieldsName='fieldsName' label='Электронная почта' :data='data'
              :showError='true' :errors='errors' :disabled='blocked' :tooltip='TooltipEnum.EMAIL' />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="input-block mb-3">
            <label class="col-form-label">Мобильный номер телефона</label>
            <core-phone-input placeholder="+7 (912) 555 22 22" name='user.phone' :data='data'
              maskedValueFieldName='maskedUserPhone' :disabled='blocked' :fieldsName='fieldsName' :showError='true'
              :errors='errors' label='Мобильный номер телефона' :tooltip='TooltipEnum.PHONE' />
          </div>
        </div>
        <div v-if='isResourseRole || isITManagerRole' class="col-sm-6">
          <div class="input-block mb-3">
            <core-text-input name='user.telegram' label='Telegram аккаунт' showLabel required :data='data'
              :showError='true' :errors='errors' placeholder='@nickname' :tooltip='TooltipEnum.TELEGRAM' />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { stringOptions } from "@/shared/validation/index.ts";
import { TooltipEnum } from '@/shared/core-form/index.ts';
import { USER_ROLE_ENUM } from "@/shared/validation/enums.ts"
import { computed } from 'vue'
const props = defineProps({
  errors: Object,
  data: Object,
  blocked: Boolean,
  roleOptions: Array,
  id: String,
  fieldsName: Object,
})

const isResourseRole = computed(() => {
  return props.data['user.role'] === USER_ROLE_ENUM.RESOURSE_MANAGER
})

const isITManagerRole = computed(() => {
  return props.data['user.role'] === USER_ROLE_ENUM.IT_MANAGER
})

</script>
<template>
  <div class="chat-header-container">
    <div class="navbar chat-header" v-if='!!data'>
      <h3>{{ participantName }}</h3>
      <h4 class='chat-header__title'>{{ requestTitle }}</h4>
      <h4 class='chat-header__title'>{{ projectTitle }}</h4>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  data: Object
})

const participantName = computed(() => {
  if (!props.data) {
    return ''
  }

  const user = props.data.participants.find(user => user.current_user === 0)
  if (!user) {
    return ''
  }

  return user.name
})

const requestTitle = computed(() => {
  if (!props.data) {
    return ''
  }

  let text = ''
  const data = props.data
  if (data.request) {
    text += `Запрос: ${data.request.number} - ${data.request.name}`
  }

  return text;
})

const projectTitle = computed(() => {
  if (!props.data) {
    return ''
  }

  let text = ''
  const data = props.data
  if (data.project) {
    text += `Проект: ${data.project.number} - ${data.project.name}`
  }

  return text;
})
</script>
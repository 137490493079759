import request from "@/shared/core-api/index.ts";

export const getContactList = async ({ page = 1, per_page = 20 }) => {
  try {
    const data = await request(`/chat?page=${page}&per_page=${per_page}`, {
      auth: true,
    });

    return data;
  } catch (e) {}
};

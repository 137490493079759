import { defineStore } from "pinia";

export const useSelectedChat = defineStore("selectedChat", {
  persist: false,
  state: () => {
    return {
      selectedChat: null,
    };
  },
  actions: {
    selectChat(data) {
      this.selectedChat = data;
    },
    clearChat() {
      this.selectedChat = null;
    },
  },
});

import request from "@/shared/core-api/index.ts";

export const getChatHistory = async ({
  chatId,
  oldestMessageId,
  per_page = 10,
}) => {
  try {
    const paramQuery =
      typeof oldestMessageId === "number"
        ? `&before_id=${oldestMessageId}`
        : "";
    const data = await request(
      `/chat/${chatId}/messages?per_page=${per_page}${paramQuery}`,
      {
        auth: true,
      }
    );

    return data;
  } catch (e) {}
};

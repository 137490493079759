<template>
  <div class='chat-row-container' :class='messageClassnameConfig.container' ref='targetElement'>
    <div class='chat-message-container' :class='messageClassnameConfig.messageContainer'>
      <div class='chat-message'>{{ message.body }}</div>
      <div class="pdf-file mt-2 d-flex" v-for='(file) in message.files'>
        <file :file='file' :onClick='openFile' :disabled='!!message.isFake' />
      </div>
      <div class='chat-message-meta'>
        <div class='chat-message-date' :class='messageClassnameConfig.date'>
          {{ dayjs(message.created_at * 1000).format('HH:mm') }}
        </div>
        <div v-if='message.is_sender'>
          <i class="la la-check" v-if='!message.is_seen'></i>
          <i class="la la-check-double" v-if='message.is_seen'></i>
        </div>
      </div>


    </div>
    <div v-if='message.isLoading' class="spinner-border chat-row-loader"></div>
    <div v-if='message.error' class="chat-row-error">
      <span>!</span>
    </div>
  </div>
  <Teleport to='body'>
    <file-view-modal v-if='selectedFile' :file='selectedFile' :onClose='closeFile' />
  </Teleport>
</template>

<script setup>
import { defineProps, computed, ref, onUnmounted, watch, nextTick } from 'vue'
import dayjs from 'dayjs';
import { useFileModal } from '@/widgets/file-view-modal/index.ts';
const { selectedFile, openFile, closeFile } = useFileModal();
const targetElement = ref(null);

const props = defineProps({
  message: Object,
  isObserve: Boolean,
  onFocus: Function,
  chatId: Number,
})

const messageClassnameConfig = computed(() => {
  return ({
    container: props.message.is_sender ? 'chat-row-container_sender' : 'chat-row-container_receiver',
    date: props.message.is_sender ? '' : 'chat-message-date_receiver',
    messageContainer: props.message.is_sender ? 'chat-message-container_sender' : ''
  })
})

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (typeof props.chatId === 'number') {
          props.onFocus && props.onFocus(props.chatId, props.message.id)
        }
      }
    });
  },
  {
    root: null,
    threshold: 0.5,
  }
);

watch(
  () => props.isObserve,
  async (newValue, oldValue) => {
    if (oldValue) {
      observer.disconnect();
    }

    if (newValue) {
      await nextTick()
      if (targetElement.value) {
        observer.observe(targetElement.value);
      }
    }
  },
  { deep: true, immediate: true }
)

onUnmounted(() => {
  if (props.isObserve) {
    observer.disconnect();
  }
})

</script>
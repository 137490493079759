<template>
  <Tooltip :title="file.name" placement="leftBottom">
    <div class="mt-2 d-flex align-items-center" :class='className ? className : "w-100 "'>
      <img className='row-file-icon' :src='fileIcon' />
      <span class='file-name row-text' :class='{ "file_disabled": disabled }' @click='clickHandler(file, disabled)'>
        {{ file.name }}
      </span>
    </div>
  </Tooltip>
</template>
<script setup>
import { Tooltip } from "ant-design-vue";
import fileIcon from '@/assets/img/fileIcons/file_icon.svg';
const props = defineProps({
  file: Object,
  disabled: {
    type: Boolean, default: false
  },
  className: {
    type: String, default: ''
  },
  onClick: {
    type: Function,
    default: () => { }
  }
})

const clickHandler = (file, disabled) => {
  if (disabled) {
    return
  }
  props.onClick && props.onClick(file)
}

</script>
<template>
  <dialog-modal :title='dialogOptions.title' :body='dialogOptions.body' :onSuccessButton='dialogOptions.onSuccessButton'
    :onCancelButton='dialogOptions.onCancelButton' :onSuccess='dialogOnSuccess' />
  <a data-bs-toggle="modal" data-bs-target="#warning-alert-modal" ref='dialogModalRef'></a>
  <div class="offcanvas offcanvas-end core-canvas__request show" tabindex="-1" id="create_project"
    aria-labelledby="create_projectLabel">
    <modal-header :title='title' :isShowSeparator='true' :onClose='() => onCloseHandler(false)' />

    <div class="offcanvas-body">
      <div class="card-body">
        <project-info v-if='currentModeRef === ProjectModalModeEnum.READ' :data='data' :user='{ user }' />
        <create-project v-else :url='url' :id='id' :data='data' :isLoadingref='isLoadingref' :onClose='onCloseHandler'
          :isChanged='isChanged' :onSuccess='onSuccess' :onCancel='onCancelEdit' />
      </div>
      <div class='buttons_container' v-if='currentModeRef === ProjectModalModeEnum.READ && user.isITManagerRole'>
        <button class="btn btn-primary submit-btn" type='button'
          @click='changeCurrentMode(ProjectModalModeEnum.EDIT)'>Редактировать</button>
      </div>
    </div>
  </div>
  <div class="offcanvas-backdrop fade show" @click='onCloseHandler(false)'></div>
</template>
<script setup>
import { onMounted, reactive, ref, computed } from 'vue';
import { useUserStore } from '@/stores/user.ts';
import { dialogOptions } from './project-modal.options.ts'
import { ProjectModalModeEnum } from './project-modal.options.ts'
import { getProjectServerData } from '@/entities/project/index.ts';
const user = useUserStore()
const props = defineProps({
  id: {
    type: Number, default: null,
  },
  onClose: Function,
  mode: {
    type: String, default: ProjectModalModeEnum.READ
  }
});
const title = ref(props.id ? '' : 'Новый проект');
const currentModeRef = ref(props.mode);
const dialogModalRef = ref();

const closeModal = () => {
  props.onClose({ isShow: false });
}

const dialogOnSuccess = () => {
  closeModal();
}

const onCloseHandler = () => {
  if (currentModeRef.value !== ProjectModalModeEnum.READ) {
    dialogModalRef.value.click();
    return;
  }

  closeModal();
}

const isChanged = computed(() => {
  if (!props.id) {
    return true;
  }

  const response = responseDataRef.value;

  if (
    data.name === response.name &&
    data.budget == response.budget &&
    data.description === response.description
  ) {
    return false;
  }

  return true;
})

const data = reactive({
  name: '',
  budget: '',
  description: '',
  budgetMasked: '',
})

const responseDataRef = ref({});

const isLoadingref = ref(false);
const url = props.id ? `/projects/${props.id}` : '/projects';

const getData = async () => {
  try {
    isLoadingref.value = true;
    if (props.id) {
      const responseData = await getProjectServerData(props.id);
      responseDataRef.value = responseData;
      Object.assign(data, responseData);
      if (responseData.budget || responseData.budget === 0) {
        data.budgetMasked = Number(responseData.budget).toLocaleString()
      }
      title.value = `${responseData.number} ${responseData.name}`
    }
    isLoadingref.value = false;
  } catch (e) {

  }
}

onMounted(async () => {
  getData();
});

const changeCurrentMode = (mode) => {
  currentModeRef.value = mode
}

const onCancelEdit = () => {
  if (props.mode === ProjectModalModeEnum.READ) {
    Object.assign(data, responseDataRef.value);
    changeCurrentMode(ProjectModalModeEnum.READ)
  } else {
    onCloseHandler();
  }
}

const onSuccess = async () => {
  if (props.mode === ProjectModalModeEnum.READ) {
    await getData();
    currentModeRef.value = ProjectModalModeEnum.READ
  } else {
    closeModal();
  }
}
</script>

import * as Yup from "yup";
import { FieldsValidation } from "@/shared/validation/index.ts";

export const managerSchema = Yup.object().shape({
  user: Yup.object().shape({
    name: FieldsValidation.notEmptyString,
    email: FieldsValidation.email,
    role: FieldsValidation.notEmptyString,
    phone: FieldsValidation.phoneNumberNotReq,
  }),
});

export const itManagerSchema = Yup.object().shape({
  user: Yup.object().shape({
    name: FieldsValidation.notEmptyString,
    email: FieldsValidation.email,
    role: FieldsValidation.notEmptyString,
    phone: FieldsValidation.phoneNumberNotReq,
    telegram: FieldsValidation.notEmptyString,
  }),
});

export const resourseManagerSchema = Yup.object().shape({
  user: Yup.object().shape({
    name: FieldsValidation.notEmptyString,
    email: FieldsValidation.email,
    role: FieldsValidation.notEmptyString,
    phone: FieldsValidation.phoneNumberNotReq,
    telegram: FieldsValidation.notEmptyString,
  }),
  company: Yup.object().shape({
    type: FieldsValidation.notEmptyString,
    tin: FieldsValidation.tin,
    okpo: FieldsValidation.okpo,
    kpp: FieldsValidation.kpp,
    okved: FieldsValidation.okved,
    phone: FieldsValidation.phoneNumberNotReq,
    ogrn: FieldsValidation.ogrn,
    short_name: FieldsValidation.notEmptyString,
    addresses: Yup.array().of(
      Yup.object().shape({
        index: FieldsValidation.index,
      })
    ),
    bank_accounts: Yup.array().of(
      Yup.object().shape({
        correspondent_account: FieldsValidation.correspondent_account,
        current_account: FieldsValidation.correspondent_account,
        bik: FieldsValidation.bik,
      })
    ),
    contracts: Yup.array().of(
      Yup.object().shape({
        number: FieldsValidation.agreementNumber,
        start_date: FieldsValidation.notEmptyString,
        end_date: FieldsValidation.notEmptyString,
      })
    ),
  }),
});

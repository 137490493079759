<template>
  <div v-if='isShowDate' class="chat-line">
    <span class="chat-date">{{ getDate() }}</span>
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue'
import dayjs from 'dayjs';
const props = defineProps({
  prevMessage: Object,
  currentMessage: Object
})

const isShowDate = computed(() => {
  const { prevMessage, currentMessage } = props
  if (!prevMessage) {
    return true
  }

  const prevMessageDate = dayjs(prevMessage.created_at * 1000)
  const currentMessageDate = dayjs(currentMessage.created_at * 1000)

  const isDifferentDay = currentMessageDate.isSame(prevMessageDate, 'day');
  return !isDifferentDay
})

const getDate = () => {
  const currentMessageDate = dayjs(props.currentMessage.created_at * 1000)
  const today = dayjs();

  const isToday = currentMessageDate.isSame(today, 'day');

  if (isToday) {
    return 'Сегодня'
  } else {
    return currentMessageDate.format('DD.MM.YY')
  }
}

</script>
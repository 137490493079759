import { defineStore } from "pinia";

export const useChatHistory = defineStore("chatHistory", {
  persist: false,
  state: () => {
    return {
      loadedConversation: {},
      conversation: {},
      contacts: {},
    };
  },
  actions: {
    createConversation(data, conversationId) {
      this.conversation[conversationId] = [...data];
    },
    getConversation(conversationId) {
      const conversationHistory = this.conversation[conversationId] || [];
      return [...conversationHistory].sort((a, b) => {
        if (a.created_at === b.created_at) {
          if (a.id <= b.id) {
            return -1;
          } else {
            return 1;
          }
        }

        return a.created_at - b.created_at;
      });
    },
    addChatHistory(data, conversationId) {
      const conversationHistory = this.conversation[conversationId];
      this.conversation[conversationId] = [...conversationHistory, ...data];
    },
    addMessage(message, conversationId, isMarkAsUnread = false) {
      if (this.conversation[conversationId]) {
        this.conversation[conversationId].push(message);
      } else {
        this.conversation[conversationId] = [message];
      }
      this.updateContactLocal(
        message.created_at,
        conversationId,
        isMarkAsUnread
      );
    },
    updateMessage(id, message, conversationId) {
      const conversationHistory = this.conversation[conversationId] || [];
      const index = conversationHistory.findIndex((item) => item.id === id);
      if (index >= 0) {
        this.conversation[conversationId][index] = message;
      } else {
        this.addMessage(message, conversationId);
      }
    },
    getContact(id) {
      return this.contacts[id];
    },
    getContacts() {
      const contacts = Object.values(this.contacts).map((contact) => contact);
      return [...contacts].sort((a, b) => b.updated_at - a.updated_at);
    },
    addOrUpdateContact(data) {
      this.contacts[data.id] = data;
    },
    addContacts(data) {
      data.forEach((contact) => {
        this.contacts[contact.id] = contact;
      });
    },
    updateContactLocal(time, conversationId, isMarkAsUnread = false) {
      const contact = this.contacts[conversationId];
      if (contact) {
        this.contacts[conversationId].updated_at = time;
        if (isMarkAsUnread) {
          this.contacts[conversationId].unread = 1;
        }
      } else {
      }
    },
    getOldestMessageId(conversationId) {
      const conversationHistory = this.conversation[conversationId] || [];
      let minIdValue = null;
      for (const message of conversationHistory) {
        if (message.id < minIdValue || !minIdValue) {
          minIdValue = message.id;
        }
      }
      return minIdValue;
    },
    addLoadedConversation(conversationId) {
      this.loadedConversation[conversationId] = true;
    },
    isConversationLoaded(conversationId) {
      return this.loadedConversation[conversationId];
    },
    readMessages(conversationId, isReader, lastReadMessageId) {
      if (isReader) {
        // Помечаем, что сообщения контакта прочитаны
        const currentContact = this.contacts[conversationId];
        if (currentContact) {
          this.contacts[conversationId].unread = 0;
        }
        return;
      }

      const currentConversation = this.conversation[conversationId];
      // Помечаем, что сообщения прочитаны
      if (currentConversation) {
        this.conversation[conversationId].forEach((message) => {
          if (message.id <= lastReadMessageId) {
            message.is_seen = 1;
          }
        });
      }
    },
  },
});

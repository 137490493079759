<template>
  <div class="core-chat-container">
    <chat-contact-list :onClick='setSelected' :selected='selected' :disabled='isLoading' />
    <div class="core-chat-messaging-container">
      <chat-messaging-header :data='selected' />
      <chat-messaging :data='selected' v-if='selected' />
      <chat-messaging-placeholder v-else />
      <chat-messaging-footer :data='selected' v-if='selected' />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router';
import { useChatHistory, useSelectedChat } from "@/entities/chat/index.ts";
import { getChatInfo } from '@/entities/chat/index.ts';
const router = useRoute();
const chatStore = useSelectedChat()
const chatHistory = useChatHistory()
const isLoading = ref(false)

const selected = ref(null)
const setSelected = (contact) => {
  const currentValue = selected.value
  if (currentValue && (currentValue.id === contact.id)) {
    return
  }

  selected.value = contact
}

const loadChat = async (id) => {
  isLoading.value = true
  try {
    const { data } = await getChatInfo(id)
    isLoading.value = false
    return data
  } catch (e) {
    console.error(e)
  }
  isLoading.value = false
}

onMounted(async () => {
  const params = router.query
  const { chatId: id } = params
  if (!id) {
    return
  }

  const selectedChatFromStore = chatStore.selectedChat
  if (selectedChatFromStore && id == chatStore.selectedChat.id) {
    chatHistory.addOrUpdateContact(selectedChatFromStore)
    selected.value = selectedChatFromStore
    chatStore.clearChat()
    return
  }

  const chatFromHistoryStore = chatHistory.getContact(id)
  if (chatFromHistoryStore) {
    selected.value = chatFromHistoryStore
    chatStore.clearChat()
    return
  }

  const response = await loadChat(id)
  if (response) {
    chatHistory.addOrUpdateContact(response)
    selected.value = response
    chatStore.clearChat()
    return
  }
})

</script>

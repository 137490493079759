import request from "@/shared/core-api/index.ts";

export const sendChatMessage = async (chatId, formData) => {
  const data = await request(`/chat/${chatId}/messages`, {
    auth: true,
    method: "POST",
    data: formData,
  });

  return data;
};

<template>
  <dialog-modal :title='dialogOptions.dialogTitle' :body='dialogOptions.dialogBody'
    :onSuccessButton='dialogOptions.dialogOnSuccessButton' :onCancelButton='dialogOptions.dialogOnCancelButton'
    :onSuccess='dialogOptions.dialogOnSuccess' />
  <a data-bs-toggle="modal" data-bs-target="#warning-alert-modal" ref='dialogModalRef'></a>
  <div class="offcanvas offcanvas-end core-canvas__request show" tabindex="-1" id="create_request"
    aria-labelledby="create_requestLabel">
    <modal-header :title='title' :isShowSeparator='true' :onClose='onCloseHandler' />

    <div class="offcanvas-body">
      <div class="card-body">
        <core-form :url="computedUrl" :onSuccess="onSuccess" v-slot="{ errors, isLoading, setErrors }" :auth='true'
          :initialValidation='validaionSchema'
          :additionalData="mode === ModeEnums.EDIT && (requestMode !== RequestTypeEnum.DRAFT_TO_NEW) ? additionalData : null">
          <div class="row input-container__column_adaptive-md">
            <div class="col">
              <div class="input-block mb-3">
                <core-text-input name='name' :data='data' :showError='true' :errors='errors' label='Название'
                  :showLabel='true' placeholder='Введите название' :required='true' />
              </div>
            </div>
          </div>
          <div class="row input-container__column_adaptive-lg">
            <div class="col">
              <div class="input-block mb-3">
                <core-calendar-input label='Начало работ' :showLabel='true' inputFormat='dd.MM.yy' name='start_date'
                  :data='data' :showError='true' :errors='errors' unmaskedValueFieldName='unmaskedStartDate'
                  :required='true' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-calendar-input label='Дедлайн проекта' :showLabel='true' inputFormat='dd.MM.yy' name='end_date'
                  :data='data' :showError='true' :errors='errors' unmaskedValueFieldName='unmaskedEndDate'
                  :required='true' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-calendar-input label='Срок предоставления КП' :showLabel='true' inputFormat='dd.MM.yy'
                  name='kp_date' :data='data' :showError='true' :errors='errors' unmaskedValueFieldName='unmaskedKpDate'
                  :required='true' />
              </div>
            </div>
          </div>
          <div class="row input-container__column_adaptive-md">
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input label='Проект' :showLabel='true' name="project_id" :data="data" :errors='errors'
                  :showError='true' :options="projectOptions" valueProp='id' displayName='title'
                  placeholder="Выберите проект" :required='true' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input label='Финансирование' :showLabel='true' name="funding_type" :data="data"
                  :errors='errors' :showError='true' :options="fundingOptions" placeholder="Выберите Финансирование "
                  :required='true' />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="input-block mb-3">
                <core-textarea-input label='Описание задачи' :showLabel='true' :data="data" name='description' rows="5"
                  :showError="true" :errors="errors" :required='true'
                  hint='* Внимание, при смене проекта описание меняется и изменения не сохраняются' />
              </div>
            </div>
          </div>
          <h3>Требования к исполнителю</h3>
          <div class="row input-container__column_adaptive-md">
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input showLabel required isSearch label='Техническая область' name="competence_tf_id" :data="data"
                  :errors='errors' :showError='true' :options="projectTfOptions" valueProp='id' displayName='name'
                  placeholder="Выберите техническую область" />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input showLabel required isSearch label='Проектная роль' name="competence_project_role_id"
                  :data="data" :errors='errors' :showError='true' :options="projectRolesOptions" valueProp='id'
                  displayName='name' placeholder="Выберите проектную роль " />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input label='Грейд' :showLabel='true' name="competence_grade_id" :data="data"
                  :errors='errors' :showError='true' :options="projectGradesOptions" valueProp='id' displayName='name'
                  placeholder="Выберите грейд" :required='true' />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input label='Требование' :showLabel='true' name="competence_tf_requirement_id" :data="data"
                  :errors='errors' :showError='true' :options="requirementsOptions" valueProp='id' displayName='text'
                  placeholder="Выберите требование" :required='true' />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input label='Обязанности' :showLabel='true' name="competence_tf_duty_id" :data="data"
                  :errors='errors' :showError='true' :options="dutiesOptions" valueProp='id' displayName='text'
                  placeholder="Выберите обязанности" :required='true' />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="input-block mb-3">
                <core-textarea-input label='Опыт аналогичных проектов' :showLabel='true' :data="data"
                  name='similar_experience' rows="5" :showError="true" :errors="errors" :required='true' />
              </div>
            </div>
          </div>

          <div v-if="mode === ModeEnums.EDIT && filesRef.files.length > 0" class="mb-4">
            <h3 class='mb-4'>Документы</h3>
            <Field v-model='data.delete_files' name="delete_files" :validateOnModelUpdate='false'
              :validateOnChange='false' :validateOnBlur='false'>
              <div class="pdf-file mt-2 d-flex" v-for='(file) in filesRef.files'>
                <file :file='file' :onClick='openFile' />
                <div class='core-input-file__cross-button'>
                  <button type="button" class="btn-close text-reset btn-sm"
                    :class="data.delete_files.includes(file.id) && 'plus'" aria-label="Close"
                    v-on:click='deleteFile(file.id)'></button>
                </div>
              </div>
            </Field>
          </div>

          <h3>Загрузите файлы</h3>
          <div class='mb-4'>
            <span class="text-secondary text-xs">Поддерживаемый формат: {{ allowedExts.join(', ') }}</span>
          </div>
          <div v-if="isShowTechnicalTask">
            <p>Техническое задание<span class="text-danger"> *</span></p>
            <div class='mb-4'>
              <core-single-file-input :allowedExts='allowedExts' :data='data' name='technical_task_file'
                :showError='true' :errors='errors' :setErrors='setErrors' />
            </div>
          </div>
          <p>Другие документы</p>
          <core-multiple-files-input :allowedExts='allowedExts' :data='data' name='files' :errors='errors'
            :showError='true' :setErrors='setErrors' />

          <div v-if="!isDataLoading">
            <div v-if="mode === ModeEnums.EDIT && (statusRef === RequestTypeEnum.DRAFT)" class='buttons_container'>
              <core-submit-button type='button' :onClick='(e, submit) => transformDraftToNew(submit)'
                :changeBeforeSubmit='true' className="btn btn-primary submit-btn" text='Перевести в статус “Новый”'
                :isLoading='isLoading || isQueryLoading' />
              <core-submit-button type='button'
                :onClick='(e, submit) => createRequest(RequestTypeEnum.DRAFT, e, submit)' :changeBeforeSubmit='true'
                className="btn btn-outline-secondary submit-btn" text='Сохранить'
                :isLoading='isLoading || isQueryLoading' spinnerOptions='text-secondary' />
            </div>
            <div v-else-if='mode === ModeEnums.EDIT' class='buttons_container'>
              <core-submit-button className="btn btn-primary submit-btn" text='Сохранить' :isLoading='isLoading'
                :disabled='isLoading || (!isChanged && !isFilesChanged)' />
              <button type='button' class="btn btn-outline-primary submit-btn"
                @click='onClose(false, null)'>Отмена</button>
            </div>
            <div v-else class='buttons_container'>
              <core-submit-button type='button'
                :onClick='(e, submit) => createRequest(RequestTypeEnum.REQUEST, e, submit)' :changeBeforeSubmit='true'
                className="btn btn-primary submit-btn" text='+ Создать запрос'
                :isLoading='isLoading && (UrlFixPriceEnums.REQUEST === computedUrl)' :disabled='isLoading' />
              <core-submit-button type='button'
                :onClick='(e, submit) => createRequest(RequestTypeEnum.DRAFT, e, submit)' :changeBeforeSubmit='true'
                className="btn btn-outline-secondary submit-btn" text='Сохранить как черновик'
                :isLoading='isLoading && (UrlFixPriceEnums.DRAFT === computedUrl)' :disabled='isLoading'
                spinnerOptions='text-secondary' />
            </div>
          </div>
        </core-form>
      </div>
    </div>
    <!-- end offcanvas-body-->
  </div>
  <file-view-modal v-if='selectedFile' :file='selectedFile' :onClose='closeFile' />
  <div class="offcanvas-backdrop fade show" @click='onCloseHandler'></div>
</template>
<script setup>
import { onMounted, ref, watch, computed } from 'vue';
import { schema, editSchema } from "@/shared/validation/createFixPriceRequest.ts";
import { ModeEnums, UrlFixPriceEnums } from '@/pages/requests/index.ts';
import { useRequest, RequestTypeEnum } from '@/features/requests/index.ts';
import { primaryAllowedExts as allowedExts } from '@/shared/core-form/index.ts';
import { useFileModal } from '@/widgets/file-view-modal/index.ts';
import { Field } from "vee-validate";
const isFirstRender = ref(true);
const { selectedFile, openFile, closeFile } = useFileModal();
const props = defineProps({
  onClose: Function,
  id: String | Number,
  mode: String,
});

const filesRef = ref({
  files: []
})

const setAdditionalData = (response, data) => {
  data.kp_date = new Date(response.kp_date * 1000)
  data.similar_experience = response.similar_experience;
  filesRef.value.files = response.files;
}

const initialData = {
  kp_date: null,
  unmaskedKpDate: '',
  similar_experience: '',
  files: [],
  delete_files: [],
}

const isFilesChanged = ref(false);

const {
  getProjectData,
  getProjectRoles,
  getRequestData,
  getRequirementAndDutiesOptions,
  fundingOptions,
  projectOptions,
  projectRolesOptions,
  projectTfOptions,
  projectGradesOptions,
  dutiesOptions,
  requirementsOptions,
  data,
  title,
  dialogModalRef,
  dialogOptions,
  isChanged,
  onSuccess,
  onCloseHandler,
  requestMode,
  isDataLoading,
  statusRef
} = useRequest(
  {
    enumsKeys: [],
    setAdditionalData: setAdditionalData,
    props,
    initialData,
    url: '/fp_requests'
  }
);

const additionalData = {
  _method: 'PUT'
}

const computedUrl = computed(() => {
  let url = ``;

  if (requestMode.value === RequestTypeEnum.REQUEST) {
    url = UrlFixPriceEnums.REQUEST;
  } else if (requestMode.value === RequestTypeEnum.DRAFT) {
    url = UrlFixPriceEnums.DRAFT;
  }

  if (props.mode === ModeEnums.EDIT) {
    url = `${UrlFixPriceEnums.REQUEST}/${props.id}`
  }

  if (requestMode.value === RequestTypeEnum.DRAFT_TO_NEW) {
    url = `${UrlFixPriceEnums.REQUEST}/${props.id}/draft_to_new`
  }

  return url;
})

watch(
  () => data.competence_tf_id,
  (value) => {
    if (props.id && isFirstRender.value) {
      isFirstRender.value = false
    } else {
      data.competence_tf_requirement_id = '';
      data.competence_tf_duty_id = '';
    }
    getRequirementAndDutiesOptions(value);
  }
)

onMounted(async () => {
  isDataLoading.value = true;
  await getProjectData();
  await getProjectRoles();
  isDataLoading.value = false;
  if (props.id) {
    getRequestData(props.id)
  }
})

const createRequest = async (type, e, submit) => {
  if (type === RequestTypeEnum.DRAFT) {
    requestMode.value = RequestTypeEnum.DRAFT;
  } else {
    requestMode.value = RequestTypeEnum.REQUEST;
  }

  submit();
}

const validaionSchema = computed(() => {
  if (requestMode.value === RequestTypeEnum.DRAFT) {
    return {}
  }

  if (requestMode.value === RequestTypeEnum.DRAFT_TO_NEW) {
    return editSchema;
  }

  if (props.mode === ModeEnums.EDIT) {
    return editSchema;
  }

  return schema;
});

const isQueryLoading = ref(false);
const transformDraftToNew = async (submit) => {
  try {
    isQueryLoading.value = true;
    requestMode.value = RequestTypeEnum.DRAFT_TO_NEW;
    submit();
    isQueryLoading.value = false;
  } catch (e) {
    isQueryLoading.value = false;
  }
}

const deleteFile = (id) => {
  isFilesChanged.value = true;
  if (data.delete_files.includes(id)) {
    data.delete_files = data.delete_files.filter(itemId => itemId !== id);
  } else {
    data.delete_files.push(id);
  }
}

const isShowTechnicalTask = computed(() => {
  if (props.mode === ModeEnums.EDIT) {
    if (filesRef.value.files === 0) {
      return true;
    }

    const technical_task = filesRef.value.files[0];
    if (technical_task && data.delete_files.includes(technical_task.id)) {
      return true;
    }

    if (!technical_task) {
      return true;
    }

    return false;
  }

  return true;
});

watch(
  [data.technical_task, data.files],
  () => {
    isFilesChanged.value = true;
  }
)

</script>
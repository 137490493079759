import { ref } from "vue";

export const useDeclineModal = (onClose) => {
  const initialSelectedDeclineResponseValue = {
    isShow: false,
    responseId: null,
  };

  const selectedDeclineResponse = ref(initialSelectedDeclineResponseValue);

  const openDeclineModal = ({ responseId, isRead = false, description }) => {
    if (isRead) {
      if (!description) {
        return;
      }

      selectedDeclineResponse.value = {
        isShow: true,
        isRead,
        description,
      };
    } else {
      selectedDeclineResponse.value = {
        isShow: true,
        responseId,
      };
    }
  };

  const closeDeclineModal = () => {
    selectedDeclineResponse.value = {
      isShow: false,
      responseId: null,
    };

    onClose && onClose();
  };

  return { selectedDeclineResponse, openDeclineModal, closeDeclineModal };
};

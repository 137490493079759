<template>
  <div class="offcanvas offcanvas-end core-canvas__card show" tabindex="-1" id="edit_employee"
    aria-labelledby="edit_employeeLabel">
    <modal-header title='Карточка пользователя' :onClose='() => onClose(null, false)' />
    <div class="offcanvas-body">
      <div class="card-body">
        <ul class="nav nav-tabs nav-tabs-bottom nav-justified core-canvas__tabs">
          <li class="nav-item core-canvas__tab">
            <a class="nav-link" ref='activeTab' href="#bottom-justified-tab1" data-bs-toggle="tab">Личные
              данные</a>
          </li>
          <li class="nav-item core-canvas__tab">
            <a class="nav-link" :class='isResourseRole ? "" : "d-none"' href="#bottom-justified-tab2"
              data-bs-toggle="tab">Реквизиты компании</a>
          </li>
          <li class="nav-item core-canvas__tab">
            <a class="nav-link" :class='isResourseRole ? "" : "d-none"' href="#bottom-justified-tab3"
              data-bs-toggle="tab">Реквизиты банка</a>
          </li>
        </ul>
        <core-form :url="`/admin/users/${id}`" :onSuccess="onSuccess" :initialValidation="getValidationSchema"
          v-slot="{ errors, isLoading, fieldsName }" :auth='true' :additionalData='additionalData'
          :isShowErrorNotification='true'>
          <div class="tab-content">

            <personal-data-tab id='bottom-justified-tab1' :errors='errors' :data='data' :fieldsName='fieldsName'
              :blocked='blocked' :roleOptions='roleOptions'></personal-data-tab>
            <company-requisites-tab id='bottom-justified-tab2' :errors='errors' :data='data' :fieldsName='fieldsName'
              :blocked='blocked'></company-requisites-tab>
            <bank-requisites-tab id='bottom-justified-tab3' :errors='errors' :data='data' :blocked='blocked'
              :fieldsName='fieldsName'></bank-requisites-tab>
            <div v-if='!blocked' class="buttons_container">
              <core-submit-button className="btn btn-primary submit-btn" text='Сохранить' :disabled='!isChanged'
                :isLoading='isLoading' />
              <button data-bs-dismiss="offcanvas" type='button' class="btn btn-outline-primary submit-btn"
                @click='onClose(null)'>Отмена</button>
            </div>
          </div>
        </core-form>
      </div>
    </div>
    <!-- end offcanvas-body-->
  </div>
  <div class="offcanvas-backdrop fade show" @click='onClose(null, false)'></div>
</template>
<script setup>
import { onMounted, reactive, computed, ref } from 'vue';
import { managerSchema, resourseManagerSchema, itManagerSchema } from "@/shared/validation/editEmployer.ts";
import request from '@/shared/core-api/index.ts';
import { USER_ROLE_ENUM } from "@/shared/validation/enums.ts"
import { useCompareValues } from '@/shared/utils/index.ts';
const activeTab = ref();

const props = defineProps({
  id: Number,
  blocked: Boolean,
  onClose: Function,
})

const { onClose } = props

const additionalData = {
  _method: 'PUT'
}

const data = reactive({
  'user.name': '',
  'user.role': '',
  'user.email': '',
  'user.phone': '',
  'user.telegram': '',
  maskedUserPhone: '',

  'company.type': '',
  'company.short_name': '',
  'company.full_name': '',
  'company.okved': '',
  'company.phone': '',
  'company.with_vat': 0,
  maskedCompanyPhone: '',
  'company.tin': '',
  'company.ogrn': '',
  'company.kpp': '',
  'company.okpo': '',

  'company.is_actual_address_equal_legal': 1,
  'company.addresses[0].index': '',
  'company.addresses[0].country': '',
  'company.addresses[0].city': '',
  'company.addresses[0].street': '',
  'company.addresses[0].house': '',
  'company.addresses[0].building': '',
  'company.addresses[0].apartment': '',

  'company.addresses[1].index': '',
  'company.addresses[1].country': '',
  'company.addresses[1].city': '',
  'company.addresses[1].street': '',
  'company.addresses[1].house': '',
  'company.addresses[1].building': '',
  'company.addresses[1].apartment': '',

  'company.bank_accounts[0].bank_name': '',
  'company.bank_accounts[0].bik': '',
  'company.bank_accounts[0].correspondent_account': '',
  'company.bank_accounts[0].current_account': '',
});

const dataFromServer = reactive({});
const { isChanged } = useCompareValues(data, dataFromServer);

let roleOptions = reactive([])

const isResourseRole = computed(() => {
  return data['user.role'] === USER_ROLE_ENUM.RESOURSE_MANAGER
})

const isITManagerRole = computed(() => {
  return data['user.role'] === USER_ROLE_ENUM.IT_MANAGER
})

onMounted(async () => {
  try {
    activeTab.value.click()
    const url = `/admin/users/${props.id}`
    const { data: userData, meta } = await request(url, {
      method: 'GET',
      auth: true,
    })

    meta && meta.allowed_roles.forEach(item => {
      roleOptions.push(item)
    })

    const { email, role, name, company, phone, telegram } = userData;
    data['user.name'] = name;
    data['user.role'] = role;
    data['user.email'] = email;
    data['user.phone'] = phone;
    data['maskedUserPhone'] = phone;
    data['user.telegram'] = telegram

    if (company) {
      data['company.type'] = company.type;
      data['company.short_name'] = company.short_name;
      data['company.full_name'] = company.full_name;
      data['company.okved'] = company.okved;
      data['company.phone'] = company.phone;
      data['maskedCompanyPhone'] = company.phone;
      data['company.with_vat'] = company.with_vat || 0;
      data['company.tin'] = company.tin;
      data['company.ogrn'] = company.ogrn;
      data['company.kpp'] = company.kpp;
      data['company.okpo'] = company.okpo;

      data['company.is_actual_address_equal_legal'] = company.is_actual_address_equal_legal ? 1 : 0;
      data['company.addresses[0].index'] = company.addresses[0] ? company.addresses[0].index : '';
      data['company.addresses[0].country'] = company.addresses[0] ? company.addresses[0].country : '';
      data['company.addresses[0].city'] = company.addresses[0] ? company.addresses[0].city : '';
      data['company.addresses[0].street'] = company.addresses[0] ? company.addresses[0].street : '';
      data['company.addresses[0].house'] = company.addresses[0] ? company.addresses[0].house : '';
      data['company.addresses[0].building'] = company.addresses[0] ? company.addresses[0].building : '';
      data['company.addresses[0].apartment'] = company.addresses[0] ? company.addresses[0].apartment : '';

      data['company.addresses[1].index'] = company.addresses[1] ? company.addresses[1].index : '';
      data['company.addresses[1].country'] = company.addresses[1] ? company.addresses[1].country : '';
      data['company.addresses[1].city'] = company.addresses[1] ? company.addresses[1].city : '';
      data['company.addresses[1].street'] = company.addresses[1] ? company.addresses[1].street : '';
      data['company.addresses[1].house'] = company.addresses[1] ? company.addresses[1].house : '';
      data['company.addresses[1].building'] = company.addresses[1] ? company.addresses[1].building : '';
      data['company.addresses[1].apartment'] = company.addresses[1] ? company.addresses[1].apartment : '';

      data['company.bank_accounts[0].bank_name'] = company.bank_accounts[0] ? company.bank_accounts[0].bank_name : '';
      data['company.bank_accounts[0].bik'] = company.bank_accounts[0] ? company.bank_accounts[0].bik : '';
      data['company.bank_accounts[0].correspondent_account'] = company.bank_accounts[0] ? company.bank_accounts[0].correspondent_account : '';
      data['company.bank_accounts[0].current_account'] = company.bank_accounts[0] ? company.bank_accounts[0].current_account : '';
    }

    Object.assign(dataFromServer, data);
  } catch (e) {

  }
})

const onSuccess = () => {
  props.onClose(null)
}

const getValidationSchema = computed(() => {
  if (isResourseRole.value) {
    return resourseManagerSchema
  }

  if (isITManagerRole.value) {
    return itManagerSchema
  }

  return managerSchema
})

</script>
<template>
  <label v-if='showLabel' class="col-form-label">
    {{ label }}
    <span v-if='required' class="text-danger">*</span>
  </label>
  <Field :name="name" v-model="data[name]" class="form-select" :validateOnModelUpdate='false' :validateOnChange='false'
    :validateOnBlur='false'>
    <multi-select class='core-select' :valueProp='valueProp' :label="displayName" :options="options"
      :resolve-on-load="false" v-model="data[name]" :placeholder="placeholder" :option-height="104" :settings='settings'
      :canClear='false' :searchable='isSearch' :class="[showError && errors[name] && 'is-invalid', className]"
      :disabled='disabled || !options.length ? true : false' ref="multiselect">
      <template #placeholder=''>
        <div class='multiselect-placeholder'>
          <span class='multiselect-placeholder-text'>{{ placeholder }}</span>
        </div>
      </template>
    </multi-select>
  </Field>
  <div class="invalid-feedback">{{ showError && errors[name] }}</div>
  <div class="emailshow text-danger" :id="data[name]"></div>
</template>
<script setup>
import { Field } from "vee-validate";
import { addFieldName, removeFieldName } from '@/shared/core-form/utils/addFieldsName.ts';
import { onMounted, onBeforeMount, watch, ref } from 'vue';
const multiselect = ref();
const props = defineProps({
  data: Object,
  settings: Object,
  disabled: Boolean,
  options: {
    type: Array, default: []
  },
  valueProp: {
    type: String, default: 'value'
  },
  placeholder: String,
  showError: Boolean,
  errors: Object,
  name: String,
  className: {
    type: String, default: ''
  },
  displayName: {
    type: String, default: 'label'
  },
  fieldsName: Object,
  label: String,
  showLabel: {
    type: Boolean, default: false
  },
  required: {
    type: Boolean, default: false
  },
  isSearch: {
    type: Boolean, default: false
  }
})

onMounted(() => {
  if (props.fieldsName && props.label && props.name) {
    addFieldName(props.fieldsName, props.name, props.label)
  }
})

onBeforeMount(() => {
  if (props.fieldsName && props.label && props.name) {
    removeFieldName(props.fieldsName, props.name, props.label)
  }
})

watch(
  () => props.options,
  (value) => {
    const stateValue = props.data[props.name]
    if (stateValue && value && value.length !== 0) {
      let option;
      if (typeof value[0] === 'Object') {
        option = value.find(item => item[props.valueProp] === stateValue);
      } else {
        const stringValue = value.find((item) => item === stateValue)
        option = stringValue
      }

      if (option) {
        multiselect.value.select(option);
      }
    }
  },
  { deep: true }
);
</script>
<style scoped>
.core-select {
  --ms-ring-width: 0;
  --ms-option-bg-pointed: #ff9b44;
  --ms-option-color-pointed: #FFF;
  --ms-option-bg-selected: #ddd;
  --ms-option-color-selected: #000000;
  --ms-option-bg-selected-pointed: #ff9b44;
  --ms-option-color-selected-pointed: #FFF;
  --ms-max-height: 10rem;
}
</style>
<template>
  <Field :name="name" v-model='data[name]' placeholder="" :validateOnModelUpdate='false' :validateOnChange='false'
    :validateOnBlur='false'>
    <input type='file' :id='name' multiple :onChange='filesOnChange' class="input-file"
      :class="{ 'is-invalid': errors[name] }" :accept="returnFileExt(allowedExts)" ref="inputRef" />
    <label v-if='maxFileLength !== data[name].length' :for="name">
      <div class='input-file__button'>
        <img :src='clipIcon' class='core-input-file__icon'>
        Выберите файлы
      </div>
      <p class='input-file__text'>{{ fileDescription.name ? `${fileDescription.name}: ${data[name].length}` :
        'Файл не выбран' }}
      </p>
    </label>
  </Field>
  <div class="invalid-feedback">{{ errors[name] }}</div>
  <div v-for='(text) in fileErrors'>
    <div class="error-container">{{ text }}</div>
  </div>
  <div class="core-input-file__list-container" :class='fileListContainerClassname' v-for='(file, index) in data[name]'>
    <div class='d-flex align-items-center'>
      <file :file='file' className='register-file-name' :class='fileNameClassname' />
      <div class='core-input-file__cross-button'>
        <button type="button" class="btn-close text-reset btn-sm" aria-label="Close"
          v-on:click='deleteFile(index, errors, setErrors)'></button>
      </div>
    </div>
    <div v-if='errors[`${name}[${index}]`]' class='text-danger my-1'>
      {{ errors[`${name}[${index}]`] }}
    </div>
  </div>
</template>
<script setup>
import { reactive, ref } from 'vue';
import { getFileExt } from '@/shared/utils/file.ts';
import { checkFileSize } from '@/shared/utils/index.ts';
import { Field } from "vee-validate";
import { returnFileExt } from '../utils/exts.ts';
import clipIcon from '@/assets/img/icons/clip.svg';

const props = defineProps({
  data: Object,
  errors: Object,
  showError: Boolean,
  name: String,
  disabled: Boolean,
  allowedExts: {
    type: Array, default: []
  },
  maxFileSize: {
    type: Number, default: 52428800
  },
  maxFileLength: {
    type: Number, default: 10
  },
  setErrors: Function,
  fileListContainerClassname: {
    type: String, default: ''
  },
  fileNameClassname: {
    type: String, default: ''
  }
});

const inputRef = ref();
const fileErrors = reactive([]);
const fileDescription = reactive({
  name: ''
});

const filesOnChange = (event) => {
  fileDescription.name = 'Число файлов'
  fileErrors.length = 0;
  for (const file of event.target.files) {
    if (props.data[props.name].length < props.maxFileLength) {
      const sizeError = checkFileSize(file.size, props.maxFileSize)
      if (sizeError) {
        !fileErrors.includes(sizeError) && fileErrors.push(sizeError);
        continue;
      };
      const ext = getFileExt(file.name);
      if (!props.allowedExts.includes(ext.toUpperCase())) {
        !fileErrors.includes('Некорректное расширение файла') && fileErrors.push('Некорректное расширение файла');
        continue;
      };
      props.data[props.name].push(file)
    } else {
      fileErrors.push(`Максимально можно загрузить ${props.maxFileLength} файлов`);
      return
    }
  }
}

const deleteFile = (index, errors, setErrors) => {
  inputRef.value.value = null;
  if (errors) {
    for (const [key, value] of Object.entries(errors)) {
      if (key.includes(props.name)) {
        setErrors({ [key]: null })
      }
    }
  }
  fileErrors.length = 0;
  props.data[props.name].splice(index, 1);
}

</script>
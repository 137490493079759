export const StatusesEnums = {
  NEW: "Новый",
  DRAFT: "Черновик",
  AGREED: "Согласовано",
  SUSPENDED: "Приостановлено",
  CONSIDIRATION: "На рассмотрении",
  ARCHIVED: "В архиве",
  DENIED: "Отклонено",
  REFUSED: "Отказано",
};

export const ModeEnums = {
  TEMPLATE: "template",
  EDIT: "edit",
};

export const UrlTMEnums = {
  DRAFT: "/tam_requests/draft",
  REQUEST: "/tam_requests",
};

export const UrlFixPriceEnums = {
  DRAFT: "/fp_requests/draft",
  REQUEST: "/fp_requests",
};

export const getStatusPermission = (status, allowedStatuses) => {
  const currentStatus = StatusesEnums[getStatus(status)];
  return allowedStatuses.includes(currentStatus);
};

export const getStatus = (status) => {
  let statusKey;
  for (const [key, value] of Object.entries(StatusesEnums)) {
    if (value === status) {
      statusKey = key;
      break;
    }
  }

  return statusKey;
};

export const isRefused = (status) => {
  if (StatusesEnums.REFUSED === status) {
    return true;
  }

  return false;
};

export const statusesSchema = {
  NEW: {
    class: "badge rounded-pill bg-inverse-info",
  },
  DRAFT: {
    class: "badge rounded-pill badge-soft-dark",
  },
  AGREED: {
    class: "badge rounded-pill bg-inverse-success",
  },
  SUSPENDED: {
    class: "badge rounded-pill badge-soft-warning",
  },
  CONSIDIRATION: {
    class: "badge rounded-pill status_under-considiration",
  },
  ARCHIVED: {
    class: "badge rounded-pill badge-soft-danger",
  },
  DENIED: {
    class: "badge rounded-pill badge-soft-danger",
  },
  REFUSED: {
    class: "badge rounded-pill badge-soft-danger",
  },
};

export const getStatusClass = (status) => {
  const statusKey = getStatus(status);
  const response = statusKey
    ? statusesSchema[statusKey].class
    : statusesSchema.NEW;
  return response;
};

export const headers = [
  {
    text: "ID запроса",
    value: "number",
    sortable: true,
    custom: false,
    width: 110,
  },
  {
    text: "Название",
    value: "name",
    sortable: true,
    custom: false,
  },
  { text: "Статус", value: "status", sortable: true, custom: true },
  {
    text: "Количество специалистов",
    value: "workers_count",
    sortable: true,
    custom: false,
  },
  { text: "Проект", value: "project_name", sortable: true, custom: true },
  { text: "Дата создания", value: "created_at", sortable: true, custom: true },
  { text: "Отклики", value: "responses_count", sortable: true, custom: false },
  { text: "Тип", value: "work_type", sortable: true, custom: false },
  { text: "Действие", value: "actions", sortable: false, custom: true },
];

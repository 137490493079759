import request from "@/shared/core-api/index.ts";

export const readChatMessages = async (chatId, messageId) => {
  const data = await request(`/chat/${chatId}/messages/read`, {
    auth: true,
    method: "POST",
    data: {
      message_id: messageId,
    },
  });

  return data;
};

<template>
  <socket-wrapper>
    <router-view v-slot="{ Component }">
      <component :is="Component" ref="component" />
    </router-view>
  </socket-wrapper>
</template>

<script>
export default {
  name: 'RouterChild',
}
</script>

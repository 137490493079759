<template>
  <dialog-modal :title='dialogOptions.dialogTitle' :body='dialogOptions.dialogBody'
    :onSuccessButton='dialogOptions.dialogOnSuccessButton' :onCancelButton='dialogOptions.dialogOnCancelButton'
    :onSuccess='dialogOptions.dialogOnSuccess' />
  <a data-bs-toggle="modal" data-bs-target="#warning-alert-modal" ref='dialogModalRef'></a>
  <div class="offcanvas offcanvas-end core-canvas__request show" tabindex="-1" id="create_request"
    aria-labelledby="create_requestLabel">
    <modal-header :title='title' :isShowSeparator='true' :onClose='onCloseHandler' />

    <div class="offcanvas-body">
      <div class="card-body">
        <core-form :url="computedUrl" :onSuccess="onSuccess" v-slot="{ errors, isLoading }" :auth='true'
          :initialValidation='validaionSchema'
          :additionalData="mode === ModeEnums.EDIT && (requestMode !== RequestTypeEnum.DRAFT_TO_NEW) ? additionalData : null">
          <div class="row input-container__column_adaptive-md">
            <div class="col">
              <div class="input-block mb-3">
                <core-text-input name='name' :data='data' :showError='true' :errors='errors' label='Название'
                  :showLabel='true' placeholder='Введите название' :required='true' />
              </div>
            </div>
          </div>
          <div class="row input-container__column_adaptive-md">
            <div class="col">
              <div class="input-block mb-3">
                <core-calendar-input label='Начало работ' :showLabel='true' inputFormat='dd.MM.yy' name='start_date'
                  :data='data' :showError='true' :errors='errors' unmaskedValueFieldName='unmaskedStartDate'
                  :required='true' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-calendar-input label='Окончание' :showLabel='true' inputFormat='dd.MM.yy' name='end_date'
                  :data='data' :showError='true' :errors='errors' unmaskedValueFieldName='unmaskedEndDate'
                  :required='true' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input showLabel required isSearch label='Город' name="city" :data="data" :errors='errors'
                  :showError='true' :options="cityOptions" placeholder="Выберите город" />
              </div>
            </div>
          </div>
          <div class="row input-container__column_adaptive-md">
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input label='Форма занятости' :showLabel='true' name="employment_form" :data="data"
                  :errors='errors' :showError='true' :options="employmentOptions" placeholder="Выберите форму занятости"
                  :required='true' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input label='Тип' :showLabel='true' name="work_type" :data="data" :errors='errors'
                  :showError='true' :options="workTypeOptions" placeholder="Выберите тип" :required='true' />
              </div>
            </div>
          </div>
          <div class='row input-container__column_adaptive-md'>
            <div class="col">
              <div class="input-block mb-3">
                <core-calendar-input label='Начало рабочего дня' placeholder='Выберите время' :showLabel='true'
                  :isTimePicker='true' :hideIcon='true' inputFormat='HH:mm' name='work_hours_start' :data='data'
                  :showError='true' :errors='errors' unmaskedValueFieldName='unmaskedWorkHoursStart' :required='true' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-calendar-input label='Конец рабочего дня' placeholder='Выберите время' :showLabel='true'
                  :isTimePicker='true' :hideIcon='true' inputFormat='HH:mm' name='work_hours_end' :data='data'
                  :showError='true' :errors='errors' unmaskedValueFieldName='unmaskedWorkHoursEnd' :required='true' />
              </div>
            </div>
          </div>
          <div class="row input-container__column_adaptive-md">
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input label='Проект' :showLabel='true' name="project_id" :data="data" :errors='errors'
                  :showError='true' :options="projectOptions" valueProp='id' displayName='title'
                  placeholder="Выберите проект" :required='true' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input label='Финансирование' :showLabel='true' name="funding_type" :data="data"
                  :errors='errors' :showError='true' :options="fundingOptions" placeholder="Выберите Финансирование "
                  :required='true' />
              </div>
            </div>
          </div>
          <div class="row input-container__column_adaptive-md">
            <div class="col">
              <div class="input-block mb-3">
                <core-textarea-input label='Описание задачи' :showLabel='true' :data="data" name='description' rows="5"
                  :showError="true" :errors="errors" :required='true'
                  hint='* Внимание, при смене проекта описание меняется и изменения не сохраняются' />
              </div>
            </div>
          </div>
          <h3>Требуемая компетенция</h3>
          <div class="row input-container__column_adaptive-md">
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input showLabel required isSearch label='Техническая область' name="competence_tf_id"
                  :data="data" :errors='errors' :showError='true' :options="projectTfOptions" valueProp='id'
                  displayName='name' placeholder="Выберите техническую область" />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input showLabel required isSearch label='Проектная роль' name="competence_project_role_id"
                  :data="data" :errors='errors' :showError='true' :options="projectRolesOptions" valueProp='id'
                  displayName='name' placeholder="Выберите проектную роль " />
              </div>
            </div>
          </div>
          <div class="row input-container__column_adaptive-md">
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input label='Грейд' :showLabel='true' name="competence_grade_id" :data="data"
                  :errors='errors' :showError='true' :options="projectGradesOptions" valueProp='id' displayName='name'
                  placeholder="Выберите грейд" :required='true' />
              </div>
            </div>
            <div class="col">
              <div class="input-block mb-3">
                <core-numeric-input label='Количество специалистов' :required='true' :showLabel='true' :data='data'
                  :errors='errors' :showError='true' name='workers_count' placeholder='Выберите количество специалистов'
                  mask="A" :maskOptions='numberStringOptions' />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input label='Требование' :showLabel='true' name="competence_tf_requirement_id" :data="data"
                  :errors='errors' :showError='true' :options="requirementsOptions" valueProp='id' displayName='text'
                  placeholder="Выберите требование" :required='true' />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="input-block mb-3">
                <core-select-input label='Обязанности' :showLabel='true' name="competence_tf_duty_id" :data="data"
                  :errors='errors' :showError='true' :options="dutiesOptions" valueProp='id' displayName='text'
                  placeholder="Выберите обязанности" :required='true' />
              </div>
            </div>
          </div>
          <div v-if="!isDataLoading">
            <div v-if="mode === ModeEnums.EDIT && (statusRef === RequestTypeEnum.DRAFT)" class='buttons_container'>
              <core-submit-button type='button' :onClick='(e, submit) => transformDraftToNew(submit)'
                :changeBeforeSubmit='true' className="btn btn-primary submit-btn" text='Перевести в статус “Новый”'
                :isLoading='isLoading || isQueryLoading' />
              <core-submit-button type='button' :onClick='(e, submit) => createRequest(RequestTypeEnum.DRAFT, submit)'
                :changeBeforeSubmit='true' className="btn btn-outline-secondary submit-btn" text='Сохранить'
                :isLoading='isLoading || isQueryLoading' spinnerOptions='text-secondary' />
            </div>
            <div v-else-if='mode === ModeEnums.EDIT' class='buttons_container'>
              <core-submit-button className="btn btn-primary submit-btn" text='Сохранить' :isLoading='isLoading'
                :disabled='isLoading || !isChanged' />
              <button type='button' class="btn btn-outline-primary submit-btn"
                @click='onClose(false, null)'>Отмена</button>
            </div>
            <div v-else class='buttons_container'>
              <core-submit-button type='button' :onClick='(e, submit) => createRequest(RequestTypeEnum.REQUEST, submit)'
                :changeBeforeSubmit='true' className="btn btn-primary submit-btn" text='+ Создать запрос'
                :isLoading='isLoading && (UrlTMEnums.REQUEST === computedUrl)' :disabled='isLoading' />
              <core-submit-button type='button' :onClick='(e, submit) => createRequest(RequestTypeEnum.DRAFT, submit)'
                :changeBeforeSubmit='true' className="btn btn-outline-secondary submit-btn"
                text='Сохранить как черновик' :isLoading='isLoading && (UrlTMEnums.DRAFT === computedUrl)'
                :disabled='isLoading' spinnerOptions='text-secondary' />
            </div>
          </div>
        </core-form>
      </div>
    </div>
    <!-- end offcanvas-body-->
  </div>
  <div class="offcanvas-backdrop fade show" @click='onCloseHandler'></div>
</template>
<script setup>
import { reactive, onMounted, ref, watch, computed } from 'vue';
import { numberStringOptions } from "@/shared/validation/index.ts";
import { schema } from "@/shared/validation/createRequest.ts";
import { fromStringToTimePickerFormat } from '@/shared/utils/date.ts';
import { ModeEnums, UrlTMEnums } from '@/pages/requests/index.ts';
import { useRequest, RequestTypeEnum } from '@/features/requests/index.ts';
const isFirstRender = ref(true);
const props = defineProps({
  onClose: Function,
  id: String | Number,
  mode: String,
});

const additionalData = {
  _method: 'PUT'
}

const setAdditionalData = (response, data) => {
  data.work_hours_start = fromStringToTimePickerFormat(
    response.work_hours_start
  );
  data.work_hours_end = fromStringToTimePickerFormat(
    response.work_hours_end
  );
  data.city = response.city;
  data.employment_form = response.employment_form;
  data.work_type = response.work_type;
  data.workers_count = String(response.workers_count);
}

const initialData = reactive({
  work_hours_start: {
    hours: 9,
    minutes: 0
  },
  unmaskedWorkHoursStart: '09:00',
  work_hours_end: {
    hours: 18,
    minutes: 0
  },
  unmaskedWorkHoursEnd: '18:00',
  city: '',
  employment_form: '',
  work_type: '',
})

const {
  getProjectData,
  getProjectRoles,
  getRequestData,
  getRequirementAndDutiesOptions,
  cityOptions,
  employmentOptions,
  workTypeOptions,
  fundingOptions,
  projectOptions,
  projectRolesOptions,
  projectTfOptions,
  projectGradesOptions,
  dutiesOptions,
  requirementsOptions,
  data,
  title,
  dialogModalRef,
  dialogOptions,
  isChanged,
  onSuccess,
  onCloseHandler,
  requestMode,
  isDataLoading,
  statusRef
} = useRequest(
  {
    enumsKeys: [],
    setAdditionalData: setAdditionalData,
    props,
    initialData,
    url: '/tam_requests'
  }
);

const computedUrl = computed(() => {
  let url = ``;

  if (requestMode.value === RequestTypeEnum.REQUEST) {
    url = UrlTMEnums.REQUEST;
  } else if (requestMode.value === RequestTypeEnum.DRAFT) {
    url = UrlTMEnums.DRAFT;
  }

  if (props.mode === ModeEnums.EDIT) {
    url = `${UrlTMEnums.REQUEST}/${props.id}`
  }

  if (requestMode.value === RequestTypeEnum.DRAFT_TO_NEW) {
    url = `${UrlTMEnums.REQUEST}/${props.id}/draft_to_new`
  }

  return url;
})

watch(
  () => data.competence_tf_id,
  (value) => {
    if (props.id && isFirstRender.value) {
      isFirstRender.value = false
    } else {
      data.competence_tf_requirement_id = '';
      data.competence_tf_duty_id = '';
    }
    getRequirementAndDutiesOptions(value);
  }
)

onMounted(async () => {
  isDataLoading.value = true;
  await getProjectData();
  await getProjectRoles();
  isDataLoading.value = false;
  if (props.id) {
    getRequestData(props.id)
  }
})

const createRequest = async (type, submit) => {
  if (type === RequestTypeEnum.DRAFT) {
    requestMode.value = RequestTypeEnum.DRAFT;
  } else {
    requestMode.value = RequestTypeEnum.REQUEST;
  }

  submit();
}

const validaionSchema = computed(() => {
  if (requestMode.value === RequestTypeEnum.DRAFT) {
    return {}
  } else {
    return schema
  }
});

const isQueryLoading = ref(false);
const transformDraftToNew = async (submit) => {
  try {
    isQueryLoading.value = true;
    requestMode.value = RequestTypeEnum.DRAFT_TO_NEW;
    submit();
    isQueryLoading.value = false;
  } catch (e) {
    isQueryLoading.value = false;
  }
}

</script>
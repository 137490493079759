<template>
  <Teleport to='body'>
    <div class='core-modal__container'>
      <div class='core-modal__content'>
        <div class="core-modal__header">
          <h5 class="modal-title text-bold">Выберите способ коммуникации</h5>
          <button type="button" class="btn-close chat-load-files__close" @click='onClose(false)'>
          </button>
        </div>
        <core-form url="/chat" :onSuccess="getSystemChat" v-slot="{ errors, isLoading, setErrors }" :auth='true'
          :onError='onError' :additionalData='{
            id: selectedContact.id,
            type: selectedContact.type
          }'>
          <div class="user-contacts-modal__buttons-container">
            <core-submit-button className='btn btn-primary auth-button' type="submit" text='Связаться в системе'
              :isLoading='isLoading' />
            <core-submit-button className='btn core-button btn-info user-contacts-modal__telegram-button' type="button"
              text='Связаться в Telegram' :isLoading='isLoading'
              :onClick='() => redirectToTelegram(selectedContact.telegram)' :disabled='!selectedContact.telegram' />
          </div>
        </core-form>
      </div>
    </div>
  </Teleport>
</template>
<script setup>
import { defineProps, onMounted } from 'vue'
import { router } from "@/router";
import { useSelectedChat } from '@/entities/chat/index.ts';
import { TELEGRAM_URL } from './user-contacts-modal.options.ts'
import { getUserDataFromServer } from '@/entities/user/index.ts';
import { useToast } from 'vue-toast-notification';
const $toast = useToast()
const chatStore = useSelectedChat()
const props = defineProps({
  isShow: {
    default: true,
    type: Boolean
  },
  onClose: {
    type: Function
  },
  selectedContact: {
    type: Object
  }
})

const getSystemChat = (response) => {
  chatStore.selectChat(response)
  router.push({ name: 'chat', query: { chatId: response.id } });
}

const checkMyTelegram = async () => {
  const myData = await getUserDataFromServer()
  if (!myData.telegram) {
    try {
      $toast.open({
        type: 'warning',
        message: 'У вас не указан Telegram-аккаунт в профиле, другие пользователи не смогут связаться с вами',
        duration: 5000,
        position: 'bottom-left'
      });
    } catch (e) { }
  }
}

const redirectToTelegram = (tgLogin) => {
  let formatedLogin = ''
  const isCorrectLogin = tgLogin.startsWith('@')
  if (isCorrectLogin) {
    formatedLogin = tgLogin.slice(1)
  } else {
    formatedLogin = tgLogin
  }
  window.open(`${TELEGRAM_URL}${formatedLogin}`)
}

onMounted(() => {
  if (!props.selectedContact.telegram) {
    $toast.open({
      type: 'warning',
      message: 'У собеседника отсутствует Telegram-аккаунт, свяжитесь, пожалуйста, внутри системы',
      duration: 5000,
      position: 'bottom-left'
    });
  }

  checkMyTelegram()
})

const onError = (e) => {
  console.error(e)
}

</script>
<template>
  <div>
    <label v-if='showLabel' class="col-form-label">
      {{ label }}
      <span v-if='required' class="text-danger">*</span>
    </label>
    <Field :standalone='isIgnore' :name="name" class="form-control" v-model='data[name]' :validateOnModelUpdate='false'
      :validateOnChange='false' :validateOnBlur='false'>
      <input ref='inputRef' class="form-control" v-model='data[maskedValueFieldName]'
        :class="{ 'is-invalid': showError && errors[name] }" :placeholder='placeholder' :disabled='disabled'
        @focus='setFocus(true)' @blur='setFocus(false)' />
    </Field>
    <p v-if='tooltip && isFocusedRef' class='core-input__hint mt-1 mb-0'>Пример: {{ tooltip }}</p>
    <div class="invalid-feedback">{{ showError && errors[name] }}</div>
    <div class="emailshow text-danger" :id="name"></div>
  </div>
</template>
<script setup>
import { Field } from "vee-validate";
import { MaskInput } from "maska";
import { addFieldName, removeFieldName } from '@/shared/core-form/utils/addFieldsName.ts';
import { onMounted, onBeforeMount, ref } from 'vue';
const props = defineProps({
  data: Object,
  disabled: Boolean,
  stringOptions: Object,
  placeholder: String,
  showError: Boolean,
  errors: Object,
  name: String,
  mask: String,
  fieldsName: Object,
  label: String,
  maskedValueFieldName: String,
  showLabel: {
    type: Boolean, default: false
  },
  required: {
    type: Boolean, default: false
  },
  isIgnore: {
    type: Boolean, default: false
  },
  tooltip: {
    type: String, default: ''
  },
  onMaskHandler: Function,
})

const inputRef = ref()

const isFocusedRef = ref()

const setFocus = (isFocused) => {
  isFocusedRef.value = isFocused
}

onMounted(() => {
  if (props.fieldsName && props.label && props.name) {
    addFieldName(props.fieldsName, props.name, props.label)
  }
})

onBeforeMount(() => {
  if (props.fieldsName && props.label && props.name) {
    removeFieldName(props.fieldsName, props.name, props.label)
  }
})

onMounted(() => {
  new MaskInput(inputRef.value, {
    preProcess: (val) => {
      return val.replace(/\D/g, '')
    },
    postProcess: (val) => {
      props.data[props.name] = val
      return Number(val).toLocaleString()
    }
  });
})
</script>
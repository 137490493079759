<template>
  <div class='core-modal__container'>
    <div class='core-modal__content'>
      <div class="core-modal__header">
        <div>
          <h5 class="modal-title text-bold">Прикрепить файлы</h5>
        </div>
        <button type="button" class="btn-close chat-load-files__close" @click='onClose(false)'>
        </button>
      </div>
      <core-form url="/files" :onSuccess="onSuccess" v-slot="{ errors, isLoading, setErrors }"
        :formatBeforeSubmit='beforeSubmitHandler' :initialValidation="schema" :auth='true' :onError='onError'>
        <div class="input-block chat-load-files__form ">
          <div class="chat-load-files__label">
            <p>Загрузите файлы</p>

          </div>
          <span class="text-gray-50 text-xs mb-2">Поддерживаемый формат: {{ allowedExts.join(', ') }}</span>
          <core-multiple-files-input :allowedExts='allowedExts' :data='data' name='files' :errors='errors'
            :showError='true' :setErrors='setErrors' fileListContainerClassname='chat-load-files__list'
            fileNameClassname='chat-load-files__file-name' :maxFileSize='FILE_LIMIT_SIZE' />
        </div>
        <div class="input-block mb-4 text-center">
          <core-submit-button className='btn btn-primary auth-button' type="submit" text='Отправить файлы'
            :isLoading='isLoading' />
        </div>
      </core-form>
    </div>
  </div>
</template>
<script setup>
import { reactive, defineProps } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { useChatHistory } from '@/entities/chat/index.ts'
import { sendChatMessage } from '@/entities/chat/index.ts'
import { FILE_LIMIT_SIZE, schema } from './chat-load-files.options.ts'
const chatHistory = useChatHistory()

const props = defineProps({
  onClose: Function,
  data: Object
})

const loadedFiles = reactive({
  files: [],
  created_at: null,
  id: null
})

const setLoadedFiles = (values) => {
  const files = []
  values.files.forEach(file => {
    files.push({ name: file.name, id: uuidv4() })
  });

  loadedFiles.value = {
    files,
    created_at: new Date().getTime() / 1000,
    id: uuidv4()
  }
}

const onError = () => {
  const body = {
    ...loadedFiles.value,
    is_sender: 1,
    error: true,
    isFake: true
  }
  chatHistory.updateMessage(loadedFiles.value.id, body, props.data.id)
}

const beforeSubmitHandler = (values) => {
  setLoadedFiles(values)
  chatHistory.addMessage({
    ...loadedFiles.value,
    is_sender: 1,
    isLoading: true,
    isFake: true,
    isNeedToScroll: true,
  }, props.data.id)
  props.onClose()
  return values
}

const onSuccess = async (response) => {
  try {
    const files = []
    response.forEach(item => {
      files.push(item.id)
    })

    await sendChatMessage(props.data.id, {
      files,
      fake_id: loadedFiles.value.id
    })

  } catch (e) {
    onError()
  }
}

const allowedExts = ['DOC', 'DOCX', 'JPEG', 'PNG', 'XLS', 'CSV', 'PPT', 'TXT', 'RTF', 'PDF', 'TIFF']
const data = reactive({
  files: [],
})
</script>
<template>
  <div class="chat-messaging-container" :key='data.id' ref='containerRef'>
    <core-list-loader :isShow='isLoading' />
    <perfect-scrollbar @scroll='onScroll' ref='scrollbarRef'>
      <div class='chat-messaging-messages'>
        <div v-for="(message, index) in chatData" :key='message.id'>
          <chat-date :currentMessage='message' :prevMessage='chatData[index - 1]' />
          <chat-row :message='message' :isObserve='index === lastReceivedMessageindex' :onFocus='readMessages'
            :chatId='data.id' />
        </div>

      </div>
    </perfect-scrollbar>

  </div>
</template>
<script setup>
import { defineProps, watch, computed, ref, nextTick } from 'vue'
import { getChatHistory, readChatMessages } from '@/entities/chat/index.ts'
import { useChatHistory } from '@/entities/chat/index.ts'
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import { MIN_MESSAGE_HEIGHT } from './chat-messaging.options.ts';
const isLoading = ref(false)
const scrollbarRef = ref(null)
const containerRef = ref(null)
const chatHistory = useChatHistory()
const readingQueryInfo = ref({
  isLoading: false,
  messageId: null,
  chatId: null,
})

const props = defineProps({
  data: {
    type: Object, default: {}
  }
})

const chatData = computed(() => {
  return chatHistory.getConversation(props.data.id)
})

const scrollToBottom = async () => {
  await nextTick()
  const scrolInstance = scrollbarRef.value;
  if (scrolInstance) {
    scrolInstance.$el.scrollTop = scrolInstance.$el.scrollHeight
  }
}

watch(
  () => [chatData.value, props.data.id],
  async ([newChatData, newId], [oldChatData, oldId]) => {
    const oldLength = oldChatData.length
    const newLength = newChatData.length

    // Когда открыли уже загруженный чат
    if (newId !== oldId) {
      scrollToBottom()
      return
    }

    // Когда количество сообщений поменялось
    if (oldLength < newLength) {
      const oldLastItem = oldChatData[oldLength - 1]
      const newLastItem = newChatData[newLength - 1]

      // Когда получено новое сообщение и диалог прокручен до конца
      if (oldLastItem !== newLastItem && newLastItem.is_sender === false) {
        const scrollHeight = scrollbarRef.value.$el.scrollHeight
        const scrollTop = scrollbarRef.value.$el.scrollTop
        const clientHeight = scrollbarRef.value.$el.clientHeight
        if (scrollTop + clientHeight >= scrollHeight - MIN_MESSAGE_HEIGHT) {
          scrollToBottom()
          return
        }
      }

      // Когда отправил сообщение
      if (oldLastItem !== newLastItem && newLastItem.isNeedToScroll) {
        scrollToBottom()
        return
      }

      const oldFirstItem = oldChatData[0]
      const newFirstItem = newChatData[0]

      // Когда запросил историю сообщений
      if (oldFirstItem !== newFirstItem) {
        const prevScrollPosition = scrollbarRef.value.$el.scrollHeight
        await nextTick()
        scrollbarRef.value.$el.scrollTop = scrollbarRef.value.$el.scrollHeight - prevScrollPosition
      }
    }
  },
  { deep: true }
)


const loadData = async (params) => {
  try {
    const { data: response } = await getChatHistory(params)
    return response
  } catch (e) {

  }
}

const onScroll = (event) => {
  const scrollTop = event.target.scrollTop
  if (scrollTop === 0) {
    loadHistory()
  }
}

const loadHistory = async () => {
  isLoading.value = true
  try {
    const currentId = props.data.id
    let params = {
      chatId: currentId,
      oldestMessageId: chatHistory.getOldestMessageId(currentId)
    }

    const response = await loadData(params)
    chatHistory.addChatHistory(response, currentId)
  } catch (e) {

  }
  isLoading.value = false
}


const getMessagesCount = () => {
  const containerHeight = containerRef.value.scrollHeight
  const minCount = Math.ceil(containerHeight / MIN_MESSAGE_HEIGHT)
  return minCount <= 10 ? 10 : minCount
}

watch(
  () => props.data,
  async (newData) => {
    if (newData.id) {
      await nextTick()
      const per_page = getMessagesCount()


      const currentId = newData.id
      if (chatHistory.isConversationLoaded(currentId)) {
        return
      }

      let params = {
        chatId: currentId,
        per_page
      }
      const oldestMessageId = chatHistory.getOldestMessageId(currentId)
      if (oldestMessageId) {
        params.oldestMessageId = oldestMessageId
      }
      const response = await loadData(params)
      chatHistory.addLoadedConversation(currentId)
      if (chatHistory.conversation[currentId]) {
        chatHistory.addChatHistory(response, currentId)
      } else {
        chatHistory.createConversation(response, currentId)
      }
    }
  },
  { deep: true, immediate: true }
)

const readMessages = async (chatIdProp, messageIdProp) => {
  try {
    const { isLoading, messageId, chatId } = readingQueryInfo.value
    if (isLoading && messageId === messageIdProp && chatIdProp === chatId) {
      return
    }

    readingQueryInfo.value = {
      isLoading: true,
      messageId: messageIdProp,
      chatId: chatIdProp
    }

    await readChatMessages(props.data.id, messageIdProp)
  } catch (e) {

  }
  readingQueryInfo.value.isLoading = false
}

const lastReceivedMessageindex = computed(() => {
  let lastIndexOfMessage = -1
  for (let i = chatData.value.length - 1; i >= 0; i--) {
    if (chatData.value[i].is_sender === false) {
      lastIndexOfMessage = i;
      break;
    }
  }

  return lastIndexOfMessage
})

</script>

<template>
  <Teleport to='body'>
    <chat-load-files v-if='isShowFileModal' :onClose='setIsShowFileModal' :data='data' />
  </Teleport>
  <div class="chat-footer chat-messaging-footer__container">
    <button class='chat-messaging-footer__button'>
      <img :src="paperClipIcon" alt="Attachment Icon" @click='setIsShowFileModal(true)' />
    </button>
    <textarea class="chat-messaging-footer__textarea" placeholder="Type message..." v-model='text' ref='textAreaRef'
      @keydown.enter.prevent.exact="sendMessage" @keydown='handleKeyDown'></textarea>
    <button class="chat-messaging-footer__send-button" type="button" @click='sendMessage'>
      <i class="fa-solid fa-paper-plane"></i>
    </button>
  </div>
</template>

<script setup>
import { defineProps, ref, nextTick, watch } from 'vue'
import { sendChatMessage } from '@/entities/chat/index.ts'
import { useChatHistory } from '@/entities/chat/index.ts'
import paperClipIcon from '../assets/paperclip.svg';
import { v4 as uuidv4 } from 'uuid';
import { messageSchema } from './chat-messaging-footer.options.ts'
import { useToast } from 'vue-toast-notification';
const $toast = useToast();
const chatHistory = useChatHistory()
const text = ref('')
const textAreaRef = ref();
const isShowFileModal = ref(false)

const setIsShowFileModal = (value) => {
  isShowFileModal.value = value
}

const changeTextAreaHeight = async (value) => {
  await nextTick()
  textAreaRef.value.style.height = 'auto';
  const scrollHeight = textAreaRef.value.scrollHeight
  textAreaRef.value.style.height = `${value ? value : scrollHeight}px`;
}

const props = defineProps({
  data: Object
})

const handleKeyDown = async (event) => {
  if (event.shiftKey && event.key === 'Enter') {
    text.value = `${text.value}\n`
    return
  }
}

watch(
  () => text.value,
  () => {
    changeTextAreaHeight()
  },
  { deep: true }
)

const validate = async (value) => {
  return await messageSchema
    .validate(value)
    .then(true)
    .catch((error) => {
      $toast.open({
        type: 'error',
        message: error.message,
        duration: 5000,
        position: 'bottom-left'
      })
      return false
    });
}

const sendMessage = async () => {
  const value = text.value.trim()
  const isValid = await validate(value)
  if (!isValid) {
    return
  }
  const _id = uuidv4()
  const created_at = new Date().getTime() / 1000
  text.value = ''
  try {
    chatHistory.addMessage({
      body: value,
      created_at: created_at,
      is_sender: 1,
      isLoading: true,
      id: _id,
      isNeedToScroll: true,
    }, props.data.id)
    await sendChatMessage(props.data.id, {
      body: value,
      fake_id: _id
    })
  } catch (e) {
    chatHistory.updateMessage(_id, {
      body: value,
      created_at: created_at,
      is_sender: 1,
      error: true,
      id: uuidv4()
    }, props.data.id)
  }
}

</script>
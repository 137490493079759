import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { computed, watch, onWatcherCleanup } from "vue";
import { getCookie } from "../utils/cookie.ts";
import config from "@/config.ts";
import { ref } from "vue";
import { useUserStore } from "@/stores/user.ts";
import { useChatNotification, useChatHistory } from "@/entities/chat/index.ts";
window.Pusher = Pusher;

export const useSocket = () => {
  const clientInstance = ref(null);
  const token = computed(() => getCookie("token"));
  const user = useUserStore();
  const chatHistory = useChatHistory();
  const chatNotification = useChatNotification();

  const subscribe = (token) => {
    if (token) {
      const { BASE_URL, SOCKET_KEY, SOCKET_HOST, SOCKET_PORT } = config;

      clientInstance.value = new Echo({
        broadcaster: "reverb",
        key: SOCKET_KEY,
        wsHost: SOCKET_HOST,
        wsPort: SOCKET_PORT,
        forceTLS: false,
        enabledTransports: ["ws", "wss"],
        authEndpoint: `${BASE_URL}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      clientInstance.value
        .private(`chat.user.${user.id}`)
        .listen(`MessageUnreadCountEvent`, (e) => {
          chatNotification.setCounter(e.chat_unread_count);
        })
        .listen("MessageCreatedEvent", (e) => {
          if (e.message.is_sender) {
            chatHistory.updateMessage(e.fake_id, e.message, e.conversation.id);
          } else {
            chatHistory.addMessage(e.message, e.conversation.id, true);
          }
        })
        .listen("ConversationCreatedEvent", (e) => {
          chatHistory.addOrUpdateContact(e);
        })
        .listen("MessageReadEvent", (e) => {
          const { conversation_id, is_reader, last_read_message_id } = e;
          chatHistory.readMessages(
            conversation_id,
            is_reader,
            last_read_message_id
          );
        });
    }
  };

  const unsubscribe = () => {
    if (clientInstance.value) {
      clientInstance.value.disconnect();
    }
  };

  watch(
    () => token.value,
    () => {
      subscribe(token.value);

      onWatcherCleanup(unsubscribe);
    },
    { deep: true, immediate: true }
  );
  return clientInstance.value;
};

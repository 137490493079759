import { ContractModelEnum } from "@/shared/enums/index.ts";
import { EntitiesEnum } from "@/shared/enums/index.ts";
import { ref } from "vue";

export const useContactsModal = () => {
  const selectedContact = ref(null);

  const openContactsModal = (id, entityType, contactType, telegram) => {
    let type = "";

    if (contactType === ContractModelEnum.TAM) {
      type += "tam_";
    }

    if (contactType === ContractModelEnum.FIX_PRICE) {
      type += "fp_";
    }

    if (entityType === EntitiesEnum.REQUEST) {
      type += "request";
    } else if (entityType === EntitiesEnum.RESPONSE) {
      type += "response";
    }

    selectedContact.value = { id, type, telegram };
  };

  const onCloseContactsModal = () => {
    selectedContact.value = null;
  };

  return { selectedContact, openContactsModal, onCloseContactsModal };
};

<template>
  <div class='chat-contacts-container' ref='containerRef'>
    <core-list-loader :isShow='isLoading || disabled' />
    <perfect-scrollbar @ps-y-reach-end='loadContacts(null)'>
      <ul style=' height: 100% '>
        <li v-for="(contact) in contacts" :key="contact.id" class='chat-contact-card' :class="{
          'chat-contact-card_selected': selected && (selected.id === contact.id),
          'chat-contact-card_disabled': disabled
        }" @click='onClickHandler(contact)'>
          <Popover :title='getCardTitle(contact)' :content="getPopoverText(contact)" placement="rightBottom"
            overlayClassName='chat-contact__popover'>
            <div class='chat-contact-card__data-container'>
              <div class='chat-contact-card__title'>{{ getCardTitle(contact) }}</div>
              <div>{{ getParticipantName(contact.participants) }}</div>
              <div>{{ fromTimestampToISOString(contact.created_at * 1000) }}</div>
            </div>
          </Popover>
          <div v-if='contact.unread' class='chat-contact-card__mark-container'>
            <div class='chat-contact-card__mark'></div>
          </div>
        </li>
      </ul>
    </perfect-scrollbar>
  </div>
</template>
<script setup>
import { Popover } from "ant-design-vue";
import { getContactList } from '../api/getContactList.ts'
import { onMounted, ref, defineProps, computed, onUnmounted, nextTick } from 'vue'
import { fromTimestampToISOString } from '@/shared/utils/date.ts';
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import { useChatHistory } from "@/entities/chat/index.ts";
import { MIN_CARD_HEIGHT } from './chat-contact-list.options.ts';
import { USER_ROLE_ENUM } from "@/shared/validation/enums.ts"
const chatHistory = useChatHistory();
const containerRef = ref()

const props = defineProps({
  onClick: {
    type: Function,
    required: true,
  },
  selected: {
    type: [Object, null]
  },
  disabled: Boolean
});

const onClickHandler = (contact) => {
  if (props.disabled) {
    return
  }
  props.onClick(contact)
}

const currentPage = ref(1)
const isLoading = ref(false)
const contactsMeta = ref(null)

const getContactsCount = () => {
  const containerHeight = containerRef.value.scrollHeight
  const minCount = Math.ceil(containerHeight / MIN_CARD_HEIGHT)
  return minCount <= 20 ? 20 : minCount
}

const loadContacts = async (paramsPage) => {
  if (isLoading.value) {
    return
  }

  await nextTick()
  const per_page = getContactsCount()

  const metaState = contactsMeta.value

  if (metaState && metaState.current_page >= metaState.last_page) {
    return
  }

  isLoading.value = true;
  try {
    const queryPage = paramsPage ? paramsPage : currentPage.value + 1
    const { data, meta } = await getContactList({ page: queryPage, per_page });
    if (!paramsPage) {
      currentPage.value += 1
    }
    chatHistory.addContacts(data)
    contactsMeta.value = meta
  } catch (e) {

  }
  isLoading.value = false
}


onMounted(() => {
  loadContacts(1)
})

const contacts = computed(() => {
  return chatHistory.getContacts()
})

onUnmounted(() => {
  chatHistory.$reset()
});


const getParticipantName = (participants) => {
  if (!participants) {
    return ''
  }

  const user = participants.find(user => user.current_user === 0)
  if (!user) {
    return ''
  }

  return user.name
}

const getCompanyName = (participants) => {
  const user = participants.find(user => user.role === USER_ROLE_ENUM.RESOURSE_MANAGER)
  if (user && user.company) {
    return user.company.short_name
  }
}

const getCardTitle = data => {
  let text = ''

  if (data.request) {
    text += `Запрос: ${data.request.number} - ${data.request.name}`
  }

  return text;
}

const getPopoverText = (data) => {
  let text = ''

  if (data.project && data.project.number && data.project.name) {
    text += `Проект: ${data.project.number} - ${data.project.name} \n`
  }

  if (data.participants) {
    text += `Компания: ${getCompanyName(data.participants)} \n`
    text += `Собеседник: ${getParticipantName(data.participants)} \n`
  }

  return text
}

</script>

<style scoped>
.ps {
  height: 100%;
  position: relative;
}
</style>
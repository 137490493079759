<template>
  <!-- Header -->
  <div class="header" ref="header">
    <!-- Logo -->
    <div class="header-left">
      <router-link :to="getHomeUrl()" class="logo">
        <img src="@/assets/img/logo.png" width="40" height="40" alt="Logo" />
      </router-link>
      <router-link :to="getHomeUrl()" class="logo2">
        <img src="@/assets/img/logo2.png" width="40" height="40" alt="Logo" />
      </router-link>
    </div>
    <!-- /Logo -->

    <div id="toggle_btn" @click="toggleSidebar">
      <span class="bar-icon">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </div>


    <!-- Header Title -->
    <div class="page-title-box">
      <h3> {{ headerTitle }}</h3>
    </div>
    <!-- /Header Title -->

    <a id="mobile_btn" class="mobile_btn" href="#sidebar" @click="toggleSidebar1"><i class="fa-solid fa-bars"></i></a>

    <!-- Header Menu -->
    <ul class="nav user-menu">
      <!-- Notifications -->
      <li v-if='!user.isAdminRole' class="nav-item dropdown">
        <a v-if='counter' class="dropdown-toggle nav-link" data-bs-toggle="dropdown" @click='localUploadNotifications'>
          <i class="fa-regular fa-bell"></i> <span v-if='counter' class="badge rounded-pill">{{ counter }}</span>
        </a>
        <router-link v-else to="/notifications" class="nav-link header__notification-button">
          <i class="fa-regular fa-bell"></i>
        </router-link>
        <div v-if="notifications.length" class="dropdown-menu notifications">
          <div class="topnav-dropdown-header">
            <span class="notification-title">Уведомления</span>
          </div>
          <div class="noti-content">
            <ul class="notification-list">
              <li v-for='notification in notifications' class="notification-message" @click="resetNotifications">
                <router-link to="notifications">
                  <div class="chat-block d-flex">
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">{{ notification && notification.data && notification.data.message
                          }}</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">{{ fromTimestampToISOString(notification.created_at * 1000)
                          }}</span>
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="topnav-dropdown-footer">
            <router-link to="notifications" @click="resetNotifications">Показать все уведомления</router-link>
          </div>
        </div>
      </li>
      <!-- /Notifications -->
      <li class="nav-item dropdown has-arrow main-drop">
        <a href="javascript:;" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
          <span class="user-img me-3"><img class='header-avatar' :src="user.avatar || photoPlaceholder"
              alt="User Image" />
            <span class="status online"></span></span>
          <span>{{ name }}</span>
        </a>
        <div class="dropdown-menu cursor-pointer">
          <div class="dropdown-item" @click='logout'>Выйти</div>
          <div v-if='user.role && !user.isAdminRole' class="dropdown-item" @click='setShowProfileSettings(true)'>
            Настройки профиля
          </div>
          <div v-if='user.isResourseManagerRole' class="dropdown-item" @click='setShowCompanySettings(true)'>
            Настройки компании
          </div>
        </div>
      </li>
    </ul>
    <!-- /Header Menu -->

    <!-- Mobile Menu -->
    <div class="dropdown mobile-user-menu">
      <a href="javascript:;" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
          class="fa-solid fa-ellipsis-vertical"></i></a>
      <div class="dropdown-menu dropdown-menu-right">
        <div class="dropdown-item" @click='logout'>Выйти</div>
        <div v-if='user.role && !user.isAdminRole' class="dropdown-item" @click='setShowProfileSettings(true)'>
          Настройки профиля
        </div>
        <div v-if='user.isResourseManagerRole' class="dropdown-item" @click='setShowCompanySettings(true)'>
          Настройки компании
        </div>
      </div>
    </div>
    <!-- /Mobile Menu -->
  </div>
  <edit-resource-company-modal v-if='isShowCompanySettings' :onClose='setShowCompanySettings' />
  <user-profile v-if='isShowProfileSettings' :onClose='setShowProfileSettings' />
  <!-- /Header -->
</template>

<script setup>
import { useUserStore } from '@/stores/user.ts';
import request from '@/shared/core-api/index.ts';
import { router } from "@/router";
import { computed, ref, onMounted, onUnmounted } from 'vue';
import photoPlaceholder from '@/assets/img/profiles/user-avatar.png';
import { useNotifications } from '@/features/request-counters/index.ts';
import { getHomeUrl, resetAccountData } from '@/shared/utils/index.ts';
import { fromTimestampToISOString } from '@/shared/utils/date.ts';

const user = useUserStore()
const { timer, counter, notifications, getNotifications, resetNotifications, uploadNotifications } = useNotifications();

const localUploadNotifications = async () => {
  try {
    await uploadNotifications();
  } catch (e) {

  }
}

onMounted(() => {
  if (timer) {
    clearTimeout(timer)
  }
  getNotifications(0);
});

onUnmounted(() => {
  timer && clearTimeout(timer)
});

const name = computed(() => {
  if (user.isAdminRole) {
    return user.role;
  }

  if (user.name) {
    return user.name
  }

  return 'Текущий пользователь'
});

const logout = async () => {
  try {
    await request('/logout', {
      method: 'POST',
      auth: true,
      headers: {
        'Accept': 'application/json',
      }
    })
    resetAccountData()
    router.push("/");
  } catch (e) {
    resetAccountData()
    router.push("/");
  }
}

const isShowProfileSettings = ref(false);
const setShowProfileSettings = (isShow) => {
  isShowProfileSettings.value = isShow
}

const isShowCompanySettings = ref(false);
const setShowCompanySettings = async (isShow) => {
  isShowCompanySettings.value = isShow
}

</script>

<script>
export default {
  inject: ['currentRoute'],
  computed: {
    headerTitle() {
      return this.currentRoute.meta?.topbar ?? ''
    },
  },
  methods: {
    toggleSidebar() {
      const body = document.body;
      // Если раскрываем обратно, то делаем все списки видимыми
      if (body.classList.contains('mini-sidebar')) {
        var subdropUL = document.getElementsByClassName("sidebar-collapse");
        body.classList.add("expand-menu");
        for (var i = 0; i < subdropUL.length; i++) {
          var ul = subdropUL[i].nextElementSibling;
          if (ul) {
            ul.classList.remove("sidebar-collapse_hidden")
          }
        }
      }
      body.classList.toggle("mini-sidebar");
      body.classList.toggle("slide-nav");
    },
    toggleSidebar1() {
      const body = document.body;
      body.classList.toggle("slide-nav");
    },
    submitForm() {
      this.$router.push("/search");
    },
  },
  mounted() {
    // Add click event listener
    this.$nextTick(() => {
      document.addEventListener("click", this.handleToggleClick);
    });

    // Скрываем все списки в сайдбаре
    document.addEventListener("mouseover", (event) => {
      event.stopPropagation();

      var body = document.body;
      var toggleBtn = document.getElementById("toggle_btn");
      var sidebar = document.getElementsByClassName("sidebar")[0];
      var subdropUL = document.getElementsByClassName("sidebar-collapse");

      if (body.classList.contains("mini-sidebar") && toggleBtn.style.display !== "none") {
        var target = event.target.closest(".sidebar");

        if (target) {
          body.classList.add("expand-menu");
          for (var i = 0; i < subdropUL.length; i++) {
            var ul = subdropUL[i].nextElementSibling;
            if (ul) {
              ul.classList.remove("sidebar-collapse_hidden")
            }
          }
        } else {
          body.classList.remove("expand-menu");
          for (var i = 0; i < subdropUL.length; i++) {
            var ul = subdropUL[i].nextElementSibling;
            if (ul) {
              ul.classList.add("sidebar-collapse_hidden")
            }
          }
        }

        event.preventDefault();
      }
    });
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleToggleClick);
  },
};
</script>
<style lang="scss" scoped>
#toggle_btn {
  @apply cursor-pointer;
}
</style>
